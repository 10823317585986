
import Point from "../../base/Point";
export default class OilPielineLabel extends Point{
    constructor(options){
        options.style = {
            label: {
                text: `${options.name}`,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#FF8900"),
                backgroundPadding: new Cesium.Cartesian2(8, 4),
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                font: "12px sans-serif",
                fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
            }
        }
        super(options)
    }
}