import Line from "../../base/Line";
import RouteLabel from "./RouteLabel";
import RouteNodePoint from "./RouteNodePoint";
import {
    getRouteLineStyle
} from "../../../style/index";
import {
    getSegmentInfo,
  } from "@/util/map";

export default class RouteLine extends Line {
    static DRAWTYPE = 'routeLine'
    constructor(options){
        let style = options.style? options.style: getRouteLineStyle({
            positions: options.positions,
            name: options.name
        })
        options.style = style
        super(options)
        this._drawtype = RouteLine.DRAWTYPE
        this._createBy = options.createBy? options.createBy: ''
        this._nodes = []
        this.initRouteline(options)
    }

    get positions () {
        return this._positions
    }

    set positions (positions) {
        try {
            for (let i = 0; i < positions.length; i++) {
                const element = positions[i];
                if (!element instanceof Cesium.Cartesian3) {
                    throw "positions的元素为Cesium.Cartesian3类型"
                }
            }
            let points = this.getLineRouteNodes()

            // 更新line的位置
            this._entity.polyline.positions = positions

            // 更新line的label位置
            this._entity.position = positions[0]

            // 更新节点位置
            for (let index = 0; index < positions.length; index++) {
                const position = positions[index];
                points[index].position = position
            }

            // 更新中点的位置
            let labels = this.getLineLabelNodes()
            for (let i = 0; i < labels.length; i++) {
                const info = getSegmentInfo(undefined, positions[i], positions[i + 1])
                const label = labels[i];
                const mindPoint = info.mindPoint
                var position = new Cesium.Cartesian3(mindPoint.x, mindPoint.y, mindPoint.z)
                label.position = position
            }
            this._positions = positions
        } catch (error) {
            console.error(error);
        }
        
    }

    set name (val) {
        this._name =val
        this._entity.name = val
        this._entity.label.text._value = val
    }

    get createBy() {
        return this._createBy
    }

    set createBy(val) {
        this._createBy = val
    }

    set show (value) {
        try {
            if (typeof value === 'boolean') {
              this._entity.show = value
              this._show = value
              for (let i = 0; i < this._nodes.length; i++) {
                let node = this._nodes[i]
                node.show = value
              }
            }else{
              throw Error('show必须是Boolean类型')
            }
          } catch (error) {
            console.error(error);
          }
    }

    get nodes() {
        return this._nodes
    }

    get drawType() {
        return this._drawtype
    }

    initRouteline(options) {
        // 初始化折线节点
        this.initPoints(options)
        this._entity.canEdit = true
        this._entity.drawType = 'RouteLine'
    }

    initPoints(options) {
        let horiDistance = 0
        for (var i = 0; i < options.positions.length; i++) {
            // 增加折线节点
            const position = options.positions[i]
            var point = new RouteNodePoint({
                id: new Date().getTime().toString() + Cesium.createGuid(),
                position: position,
                index: `${i + 1}`,
                root: this,
                show: true,
            })
            point.entity.canEdit = true
            point.entity.drawType = 'RouteLine'
            this.addNode(point)

            // 增加两点距离的label
            if (i > 0) {
                const info = getSegmentInfo(undefined, options.positions[i - 1], options.positions[i])
                horiDistance = horiDistance + info.length
            }
        }
        this._entity.horiDistance = horiDistance
    }

    getLineRouteNodes () {
        let nodes = []
        for (let i = 0; i < this.nodes.length; i++) {
            const node = this.nodes[i]
            if (node instanceof RouteNodePoint) {
                nodes.push(node)
            }
        }
        return nodes
    }

    getLineLabelNodes () {
        let nodes = []
        for (let i = 0; i < this.nodes.length; i++) {
            const node = this.nodes[i]
            if (node instanceof RouteLabel) {
                nodes.push(node)
            }
        }
        return nodes
    }

    toHeighLight() {
        let points = this.getLineRouteNodes()
        for (let index = 0; index < points.length; index++) {
            const element = points[index];
            element.toHeighLight()
        }
    }

    cancelHeighLight() {
        let points = this.getLineRouteNodes()
        for (let index = 0; index < points.length; index++) {
            const element = points[index];
            element.cancelHeighLight()
        }
    }

    addNode(node) {
        this._nodes.push(node)
    }
}