import Point from "../base/Point";
import NodeMap from "../index";
import { tangentialCircle } from "@/util/map";
import { getMarkCircleStyle } from "../../style/index";

export default class TemporaryMarkCircle extends Point {
    static DRAWTYPE = "TemporaryMarkCircle";
    static ALPHA = "33";
    static COLOR = "#1164FF";
    constructor(options) {
        options = Object.assign({
            color: TemporaryMarkCircle.COLOR
        }, options);
        let defaultProps = Object.assign({
            category: NodeMap.Node.MARK,
            textColor: "#FFFFFF"
        }, options);
        defaultProps.style = defaultProps.style ? defaultProps.style : getMarkCircleStyle(defaultProps);
        super(defaultProps);
        this._color = defaultProps.color;
        this._drawType = TemporaryMarkCircle.DRAWTYPE;
        this._createBy = defaultProps.createBy ? defaultProps.createBy : "";
        this._canEdit = false;
        this.initTemporaryMarkCircle(defaultProps);
    }
    get createBy() {
        return this._createBy;
    }
    set createBy(val) {
        this._createBy = val;
    }
    get drawType() {
        return this._drawType;
    }
    get entity() {
        return this._entity;
    }
    get canEdit() {
        return this._canEdit;
    }
    get color() {
        return this._color;
    }
    initTemporaryMarkCircle(defaultProps) {
        const cartographic = Cesium.Cartographic.fromCartesian(defaultProps.position);
        const longitude = Cesium.Math.toDegrees(cartographic.longitude);
        const latitude = Cesium.Math.toDegrees(cartographic.latitude);
        let linePoints = tangentialCircle([longitude, latitude], defaultProps.radius);
        this.entity.ellipse = {
            semiMinorAxis: defaultProps.radius * 1,
            semiMajorAxis: defaultProps.radius * 1,
            material: Cesium.Color.fromCssColorString('rgba(0, 0, 0, 0)'),
            outline: false,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        };
        this.entity.polyline = new Cesium.PolylineGraphics({
            width: 5,
            positions: linePoints,
            material: Cesium.Color.fromCssColorString(defaultProps.color),
            clampToGround: true
        });
        this.entity.polygon = new Cesium.PolygonGraphics({
            hierarchy: new Cesium.PolygonHierarchy(linePoints),
            material: Cesium.Color.fromCssColorString(defaultProps.color + TemporaryMarkCircle.ALPHA),
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        });
    }
    draw(position, radius) {
        if (!radius || radius <= 0) return;
        this.entity.ellipse.semiMinorAxis = new Cesium.CallbackProperty(function() {
            return radius;
        }, false);
        this.entity.ellipse.semiMajorAxis = new Cesium.CallbackProperty(function() {
            return radius;
        }, false);
        const cartographic = Cesium.Cartographic.fromCartesian(position);
        const longitude = Cesium.Math.toDegrees(cartographic.longitude);
        const latitude = Cesium.Math.toDegrees(cartographic.latitude);
        let linePoints = tangentialCircle([longitude, latitude], radius);
        this.entity.polyline.positions = new Cesium.CallbackProperty(function() {
            return linePoints;
        }, false);
        this.entity.polygon.hierarchy = new Cesium.CallbackProperty(function() {
            return new Cesium.PolygonHierarchy(linePoints);
        }, false);
    }
}