
class Node {
    // type
    static POINT = "Point"
    static LINE = "LineString"
    static POLYGON = "Polygon"
    // category
    static BASECATEGORY = "BaseNode"
    static MARK = "Mark"
    static INSPECTION = "Inspection"
    static NAVIGATION = "Navigation"
    static UAV = "Uav"
    static NOFLYZONE = "NoFlyZone"
    static WARNING = "WarnPoint"
    static AIRPORT = "Airport"
    static BASESTATION = 'BaseStation'
    static HANGAR = 'Hangar'
    static ROUTE = 'Route'
    static OPERATIONAREA = 'OperationArea'
    constructor(options) {
        options = Object.assign({
            category: Node.CATEGORY
        }, options);
        this._id =  options.id
        this._name = options.name
        this._parent = options.parent
        this._category = options.category
        this._selected = false
        this._hovering = false
    }

    set parent(node) {
        this._parent = node
    }

    get parent() {
        return this._parent
    }

    get id (){
        return this._id
    }

    get name (){
        return this._name
    }

    set name(val) {
        this._name = val
    }

    get category () {
        return this._category
    }
    
    set selected(value) {
        this._selected = value
    }

    get selected() {
        return this._selected
    }

    set hovering(value) {
        this._hovering = value
    }

    get hovering() {
        return this._hovering
    }
    initAnimation() {}
    cancelAnimation() {}
    onSelect(){
        this.selected = true
    }
    unSelect() {
        this.selected = false
    }
    hover() {
        this._hovering = true
    }
    leave() {
        this._hovering = false
    }
    move(position){}

    getRootNode() {
        if (this.parent) {
            return this.parent.getRootNode()
        }
        return this
    }
}
export default Node;
  