import MarkPoint from "../../mark/MarkPoint";
import NodeMap from "../../index";
import oilGasWell from "@/assets/img/map/oilGasWell.png";
import oilGasWellActive from "@/assets/img/map/oilGasWell-active.png";
import { getOilGasWellPointStyle } from "../../../style/index";

export default class GasWell extends MarkPoint {
  static DRAWTYPE = "oilGasWell";
  constructor(options) {
    let defaultProps = Object.assign({
      category: NodeMap.Node.INSPECTION,
      icon: oilGasWell,
      iconHover: oilGasWellActive,
      iconSelecte: oilGasWellActive,
    }, options)
    defaultProps.style = getOilGasWellPointStyle(defaultProps);
    super(defaultProps);
    this._drawType = GasWell.DRAWTYPE;
    this.initOilGasWellPoint(defaultProps);
  }

  initOilGasWellPoint() {
    this.entity.drawType = GasWell.DRAWTYPE;
  }
}