import { newColorRibon } from "../materials/PolylineTrailMaterial";
import { colors } from "../mapConfig";
import warningPoint from "@/assets/img/map/warning.png";
import {
  getNavigationLineStyle,
  getNavigationLineStartStyle,
  getNavigationLineEndStyle,
} from "./NavigationLineStyle";
import { 
  getRouteLineStyle,
  getRouteStyle
} from "./RouteLine";
import { 
  getStorageTankStyle, 
  getMeasurementStationStyle, 
  getStationStorageStyle,
  getPowerEquipmentStyle,
  getOilWellPointStyle,
  getOilGasWellPointStyle,
  getOilPiepelinetStyle,
  getOilRoadStyle,
  getPowerLineStyle
} from "./oilGas";
import { 
  getMarkPointStyle,
  getMarkCircleStyle,
  getMarkLineStyle,
  getTemporaryMarklinetStyle,
  getMarkPolygonStyle
} from "./mark";
import { 
  getHomePointStyle,
  getProjectCenterPointStyle
} from "./project";
import { getOperationAreaStyle } from './OperationAreaStyle'

// 统一业务点lable点样式
const getLabelPoint = (text, drawType = "", img, color = colors[0]) => {
  if (drawType === "project") {
    return getProjectPointStyle();
  }
  const result = {
    billboard: {
      image:
        drawType === "project"
          ? "/img/map/地标.png"
          : img || "/img/map/label-point.png",
      pixelOffset: new Cesium.Cartesian2(0, -10),
      eyeOffset: new Cesium.Cartesian3(0, 0, -10),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      width: drawType === "project" ? 26 : 20,
      height: drawType === "project" ? 26 : 26,
      color: Cesium.Color.fromCssColorString(color),
    },
    point: {
      pixelSize: 1.0,
      color: Cesium.Color.fromCssColorString("rgba(0,255,255,1)"),
    },
  };
  if (drawType !== "home") {
    result.label = {
      text: text,
      font: "14px",
      eyeOffset: new Cesium.Cartesian3(0, 0, -14),
      pixelOffset: new Cesium.Cartesian2(70, -10),
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("rgba(0,0,0,0.6)"),
      backgroundPadding: new Cesium.Cartesian2(8, 8),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
    };
  }
  return result;
};

// 顺序点样式
const getNodePoint = (drawType, label, isHome) => {
  let result = {};
  if (isHome && drawType === "flyline") {
    result.billboard = {
      image: "/img/map/take-off-point.png",
      pixelOffset: new Cesium.Cartesian2(0, -10),
      eyeOffset: new Cesium.Cartesian3(0, 0, -10),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      width: 32,
      height: 32,
    };
    result.point = {
      pixelSize: 1.0,
      eyeOffset: new Cesium.Cartesian3(0, 0, -1),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      color: Cesium.Color.fromCssColorString("rgba(255,255,255, 1)"),
    };
  } else {
    if (drawType === "flyline") {
      result.label = {
        text: `${label}`,
        scale: 0.5,
        font: "28px sans-serif",
        style: Cesium.LabelStyle.FILL,
        eyeOffset: new Cesium.Cartesian3(0, 0, -20),
        fillColor: Cesium.Color.BLACK,
        outlineColor: Cesium.Color.WHITE,
        outlineWidth: 0,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
      };
    }
    result.point = {
      pixelSize: drawType === "flyline" ? 20.0 : 10.0,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      eyeOffset: new Cesium.Cartesian3(0, 0, -10),
      color: Cesium.Color.fromCssColorString("rgba(255,255,255, 1)"),
      heightReference: Cesium.HeightReference.NONE,
    };
  }
  return result;
};

// 线样式
const getLineStyle = (drawType, map, color = "#3573FF") => {
  const mat =
    drawType === "flyline"
      ? newColorRibon(map)
      : Cesium.Color.fromCssColorString(color);
  return {
    width: drawType === "flyline" ? 2.0 : 8.0,
    material: mat,
    depthFailMaterial: new Cesium.PolylineDashMaterialProperty({
      color: Cesium.Color.fromCssColorString("rgba(255,177, 0, 1)"),
    }),
  };
};

const getPolygonStyle = (points, isClampToGround, color = "#3573FF") => {
  const line = points.slice();
  line.push(line[0]); // 构造闭合的线
  const colorObj = Cesium.Color.fromCssColorString(color);
  return {
    polyline: {
      width: 8.0,
      clampToGround: true,
      // zIndex: -10,
      material: colorObj,
      positions: new Cesium.CallbackProperty(function () {
        return line;
      }, true),
    },
    polygon: {
      hierarchy: new Cesium.CallbackProperty(function () {
        return new Cesium.PolygonHierarchy(points);
      }, false),
      fill: true,
      material: colorObj.withAlpha(0.34),
      heightReference: isClampToGround
        ? Cesium.HeightReference.CLAMP_TO_GROUND
        : Cesium.HeightReference.NONE,
    },
  };
};

const getDistcanceLabelStyle = (dis, suffix) => {
  return {
    label: {
      text: `${dis}${suffix}`,
      scale: 0.5,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#000"),
      backgroundPadding: new Cesium.Cartesian2(16, 16),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      font: "28px sans-serif",
      // pixelOffset: new Cesium.Cartesian3(0, 0, -10000),
      pixelOffset: new Cesium.Cartesian2(0, -30),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
    },
    point: {
      pixelSize: 5.0,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      eyeOffset: new Cesium.Cartesian3(0, 0, -10),
      color: Cesium.Color.fromCssColorString("rgba(255,255,255, 0.1)"),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
    },
  };
};

const getLabelStyle = (label) => {
  return {
    label: {
      text: `${label}`,
      scale: 0.5,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#00f"),
      backgroundPadding: new Cesium.Cartesian2(16, 16),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      font: "28px sans-serif",
      // eyeOffset: new Cesium.Cartesian3(0, 0, -10000),
      pixelOffset: new Cesium.Cartesian2(0, -30),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
    },
    point: {
      pixelSize: 5.0,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      eyeOffset: new Cesium.Cartesian3(0, 0, -10),
      color: Cesium.Color.fromCssColorString("rgba(255,255,255, 1)"),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
    },
  };
};

const getProjectPointStyle = () => {
  return {
    billboard: {
      image: "/img/map/project-positioning.png",
      pixelOffset: new Cesium.Cartesian2(0, -10),
      eyeOffset: new Cesium.Cartesian3(0, 0, -10),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      width: 26,
      height: 36,
    },
    point: {
      pixelSize: 1.0,
      color: Cesium.Color.fromCssColorString("rgba(0,255,255,1)"),
    },
  };
};

const getWaringNode = (selected) => {
  let result = {};
  result.billboard = {
    image: selected
      ? "/img/map/warning/warning-active.png"
      : "/img/map/warning/warning.png",
    pixelOffset: new Cesium.Cartesian2(0, -10),
    eyeOffset: new Cesium.Cartesian3(0, 0, -10),
    // disableDepthTestDistance: 0,
    // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
    width: 24,
    height: 32,
  };
  result.point = {
    pixelSize: 1.0,
    eyeOffset: new Cesium.Cartesian3(0, 0, -1),
    // disableDepthTestDistance: 0,
    color: Cesium.Color.fromCssColorString("rgba(255,255,255, 1)"),
  };
  return result;
};

const getWarningPointStyle = () => {
  return {
    billboard: {
      image: warningPoint,
      width: 28,
      height: 36,
      // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 1000 * 2.8
      ),
    },
  };
};



export {
  getLabelPoint,
  getNodePoint,
  getLineStyle,
  getPolygonStyle,
  getDistcanceLabelStyle,
  getLabelStyle,
  getProjectPointStyle,
  getWaringNode,
  getOilPiepelinetStyle,
  getNavigationLineStyle,
  getNavigationLineStartStyle,
  getNavigationLineEndStyle,
  getRouteLineStyle,
  getWarningPointStyle,
  getOilWellPointStyle,
  getOilGasWellPointStyle,
  getStorageTankStyle,
  getMeasurementStationStyle,
  getStationStorageStyle,
  getPowerEquipmentStyle,
  getOilRoadStyle,
  getPowerLineStyle,
  getMarkPointStyle,
  getMarkCircleStyle,
  getMarkLineStyle,
  getHomePointStyle,
  getProjectCenterPointStyle,
  getTemporaryMarklinetStyle,
  getMarkPolygonStyle,
  getRouteStyle,
  getOperationAreaStyle
};
