import Line from "../../base/Line";
import NodeMap from "../../index"
import planeIcon from "@/assets/img/map/XC_64x64_.png";

export default class UAVRoute extends Line {
  static DRAWTYPE = "UAVRoute";
  constructor (options){
    let defaultProps = Object.assign({
      style: {
        position: options.positions,
        billboard: {
          image: planeIcon,
          width: 56,
          height: 56,
          rotation: -Cesium.Math.toRadians(Number(options.uavYaw? options.uavYaw: 0)),
        },
        polyline: {
          positions: options.positions,
          material: Cesium.Color.fromCssColorString("#AAFF00"),
          width: 2.0,
          depthFailMaterial: Cesium.Color.fromCssColorString("#AAFF00"),
        }
      },
      category: NodeMap.Node.UAV
    }, options)
    super(defaultProps);
    this._drawType = UAVRoute.DRAWTYPE
    this._uavYaw = defaultProps.uavYaw
    this.initUAVRoute(defaultProps);
    this.initAnimation()
    this._canEdit = false
  }

  get canEdit() {
    this._canEdit
  }

  get uavYaw() {
    return this._uavYaw
  }
  
  set uavYaw(value) {
    this.entity.billboard.rotation = -Cesium.Math.toRadians(value || 0);
    this._uavYaw = value
  }

  get positions() {
    return this._positions
  }

  set positions(value) {
    this.entity.position = value[value.length - 1]
    this._positions = value
  }

  initUAVRoute() {
    this._entity.drawType = UAVRoute.DRAWTYPE;
  }

  initAnimation() {
    this.entity.polyline.positions = new Cesium.CallbackProperty(()=>{
      return this.positions
    }, false)
  }

  onSelect(){}
  unSelect(){}
  hover() {}
  leave() {}
  toHeighLight() {}
  cancelHeighLight() {}
}