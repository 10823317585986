import NodeMap from "../index";
import Point from "../base/Point";
import { getMarkPointStyle } from "../../style/index";
import MarkIcon from "../../canvas/MarkIcon";
import firePoint from "@/assets/img/map/firePoint.png";
import firePointActive from "@/assets/img/map/firePoint-active.png";

export default class MarkPoint extends Point {
  static DRAWTYPE = "MarkPoint";
  constructor(options) {
    let color = options.color? options.color: "#0096FF"
    let defaultProps = Object.assign({
      isFire: false,
      category: NodeMap.Node.MARK,
      textColor: "#FFFFFF"
    }, options);
    // 正常图标
    defaultProps.icon = options.icon? 
      options.icon: 
      (
        options.isFire? firePoint: MarkIcon.getIcon(color)
      )
    // 悬停图标
    defaultProps.iconHover = options.icon? 
      options.iconHover: 
      (
        options.isFire? firePointActive: MarkIcon.getHoverIcon(color)
      )
    // 选中图标
    defaultProps.iconSelecte = options.icon? 
      options.iconSelecte: 
      (
        options.isFire? firePoint: MarkIcon.getSelecteIcon(color)
      )
    defaultProps.color = color
    defaultProps.style = defaultProps.style? defaultProps.style: getMarkPointStyle(defaultProps);
    super(defaultProps);
    this._isFire = defaultProps.isFire
    this._color = defaultProps.color
    this._icon = defaultProps.icon;
    this._iconHover = defaultProps.iconHover;
    this._iconSelecte = defaultProps.iconSelecte;
    this._drawType = MarkPoint.DRAWTYPE;
    this._createBy = defaultProps.createBy ? defaultProps.createBy : "";
    this._canEdit = typeof defaultProps.canEdit === 'boolean' ? defaultProps.canEdit : true;
    this.initMarkPoint();
    
  }
  get name () {
    return this._name
  }

  set name(value) {
    this.entity.label.text._value = value;
    this._name = value
  }

  get createBy() {
    return this._createBy;
  }

  set createBy(val) {
    this._createBy = val;
  }

  get drawType() {
    return this._drawType;
  }

  get entity() {
    return this._entity;
  }

  get canEdit() {
    return this._canEdit;
  }

  get icon() {
    return this._icon;
  }

  set icon(value) {
    this._icon = value
  }

  get iconHover() {
    return this._iconHover;
  }

  set iconHover(value) {
    this._iconHover = value
  }

  get iconSelecte() {
    return this._iconSelecte;
  }

  set iconSelecte(value) {
    this._iconSelecte = value
  }

  get color() {
    return this._color
  }

  set color(value) {
    if (!this.isFire) {
      this.icon = MarkIcon.getIcon(value);
      this.iconHover = MarkIcon.getHoverIcon(value);
      this.iconSelecte = MarkIcon.getSelecteIcon(value);
      this.entity.label.backgroundColor = new Cesium.Color.fromCssColorString(value)
    }
    this._color = value
  }

  get isFire() {
    return this._isFire
  }

  set isFire(value) {
    if (value) {
      this.icon = firePoint;
      this.iconHover = firePointActive;
      this.iconSelecte = firePointActive;
      this.entity.label.backgroundColor = new Cesium.Color.fromCssColorString('#FF0000')
    } else {
      this.icon = MarkIcon.getIcon(this.color);
      this.iconHover = MarkIcon.getHoverIcon(this.color);
      this.iconSelecte = MarkIcon.getSelecteIcon(this.color);
      this.entity.label.backgroundColor = new Cesium.Color.fromCssColorString(this.color)
    }
    this._isFire = value
  }

  move(args) {
    this.position = args.position;
  }

  initMarkPoint() {
    this.entity.canEdit = true;
    this.entity.drawType = MarkPoint.DRAWTYPE;
    this.initState();
  }

  initState() {
    this.entity.billboard.image = new Cesium.CallbackProperty(() => {
      return this.selected
        ? this.iconSelecte
        : this.hovering
        ? this.iconHover
        : this.icon;
    }, true);
  }
}
