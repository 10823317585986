import Point from './../../base/Point';
import MarkPoint from "../../mark/MarkPoint";
import NodeMap from "../../index";
import hangar from "@/assets/img/map/hangar.png";
import hangarActive from "@/assets/img/map/hangar-active.png";

export class HangarPoint extends MarkPoint {
    static DRAWTYPE = "hangarPoint";
    constructor(options) {
        let defaultProps = Object.assign({
            category: NodeMap.Node.HANGAR,
            icon: hangar,
            iconHover: hangarActive,
            iconSelecte: hangarActive
        }, options);
        defaultProps.style = {
            sn: defaultProps.sn,
            label: {
                text: `${defaultProps.name}`,
                scale: 0.5,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                backgroundPadding: new Cesium.Cartesian2(16, 8),
                font: "28px sans-serif",
                pixelOffset: new Cesium.Cartesian2(0, 32),
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 50
                )
            },
            billboard: {
                image: defaultProps.icon,
                width: 33,
                height: 33,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 1000 * 2.8
                )
            }
        };
        super(defaultProps);
        this._drawType = HangarPoint.DRAWTYPE;
        this.initHangarPoint(defaultProps);
    }
    initHangarPoint() {
        this.entity.drawType = HangarPoint.DRAWTYPE;
    }
}

export class HangarShowPoint extends Point {
    constructor(options) {
        options.style = {
            id: options.id,
            position: options.position,
            sn: options.sn,
            label: {
                text: `${options.name}`,
                scale: 0.5,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                backgroundPadding: new Cesium.Cartesian2(16, 8),
                font: "28px sans-serif",
                pixelOffset: new Cesium.Cartesian2(0, 32),
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 50
                )
            },
            billboard: {
                image: hangar,
                width: 33,
                height: 33,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 1000 * 2.8
                )
            }
        };
        super(options);
        this._canEdit = false;
        this._selected = false;
    }
}