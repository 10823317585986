
const getMarkPointStyle = (args) => {
  return {
    label: {
      text: `${args.name}`,
      scale: 0.5,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString(args.color),
      backgroundPadding: new Cesium.Cartesian2(16, 8),
      fillColor: Cesium.Color.fromCssColorString(args.textColor),
      font: "28px sans-serif",
      pixelOffset: new Cesium.Cartesian2(0, 32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 50
      ),
    },
    billboard: {
      image: args.icon,
      width: 32,
      height: 32,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 1000 * 2.8
      ),
    }
  };
};

const getMarkCircleStyle = (args) => {
  return {
    label: {
      text: `${args.name}`,
      scale: 0.5,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString(args.color),
      backgroundPadding: new Cesium.Cartesian2(16, 8),
      fillColor: Cesium.Color.fromCssColorString(args.textColor),
      font: "28px sans-serif",
      pixelOffset: new Cesium.Cartesian2(0, 32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 50
      ),
    },
    point: {
      pixelSize: 10,
      color: Cesium.Color.WHITE,
      outlineColor: Cesium.Color.WHITE,
      outlineWidth: 2,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
    }
  };
};

const getMarkLineStyle = (args) => {
  return {
    position: args.positions[0],
    label: {
      text: `${args.name}`,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString(args.color),
      backgroundPadding: new Cesium.Cartesian2(8, 4),
      font: "14px sans-serif",
      fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
      pixelOffset: new Cesium.Cartesian2(0, -32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 400
      ),
    },
  };
};

const getMarkPolygonStyle = (args) => {
  return {
    position: args.positions[0],
    label: {
      text: `${args.name}`,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString(args.color),
      backgroundPadding: new Cesium.Cartesian2(8, 4),
      font: "14px sans-serif",
      fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
      pixelOffset: new Cesium.Cartesian2(0, -32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 400
      ),
    },
  };
};

const getTemporaryMarklinetStyle = (args) => {
  return {
    position: args.positions[0]
  }
};

export {
  getMarkPointStyle,
  getMarkCircleStyle,
  getMarkLineStyle,
  getTemporaryMarklinetStyle,
  getMarkPolygonStyle
};