import MarkPoint from "../../mark/MarkPoint";
import NodeMap from "../../index";
import oilWell from "@/assets/img/map/oilWell.png";
import oilWellActive from "@/assets/img/map/oilWell-active.png";
import { getOilWellPointStyle } from "../../../style/index";

export default class OilWellPoint extends MarkPoint {
  //枚举
  static DRAWTYPE = "oilWell";
  constructor(options) {
    let defaultProps = Object.assign({
      category: NodeMap.Node.INSPECTION,
      icon: oilWell,
      iconHover: oilWellActive,
      iconSelecte: oilWellActive,
    }, options)
    defaultProps.style = getOilWellPointStyle(defaultProps);
    super(defaultProps);
    this._drawType = OilWellPoint.DRAWTYPE
    this.initOilWellPoint(defaultProps);
  }

  initOilWellPoint() {
    this.entity.drawType = OilWellPoint.DRAWTYPE;
  }
}
