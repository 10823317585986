const getOperationAreaStyle = (args) => {
  return {
    position: args.positions[0],
    label: {
      text: `${args.name}`,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString(args.color),
      backgroundPadding: new Cesium.Cartesian2(8, 4),
      font: "14px sans-serif",
      fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
      pixelOffset: new Cesium.Cartesian2(0, -32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 400 
      ),
    },
  };
};

export  {
  getOperationAreaStyle
}