import NodeMap from "../../index";
import Point from "../../base/Point";
import MarkCircle from "../../mark/MarkCircle";
import { tangentialCircle } from "@/util/map";

const calculatePointForCircle = (position, radius) => {
    const cartographic = Cesium.Cartographic.fromCartesian(position);
    const longitude = Cesium.Math.toDegrees(cartographic.longitude);
    const latitude = Cesium.Math.toDegrees(cartographic.latitude);
    let linePoints = tangentialCircle([longitude, latitude], radius);
    return linePoints;
}

export class NoFlyCircle extends MarkCircle {
    static DRAWTYPE = "noFlyCircle";
    constructor(options) {
        let defaultProps = Object.assign({
            category: NodeMap.Node.NOFLYZONE,
            color: options.color || "#0063FF",
        }, options);
        defaultProps.style = {
            position: defaultProps.position,
            label: {
                text: `${defaultProps.name}`,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                backgroundPadding: new Cesium.Cartesian2(8, 4),
                font: "14px sans-serif",
                fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
                pixelOffset: new Cesium.Cartesian2(0, -32),
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 400
                )
            },
            point: {
                pixelSize: 10,
                color: Cesium.Color.WHITE,
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 2,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
            }
        };
        super(defaultProps);
        this._drawType = NoFlyCircle.DRAWTYPE;
        this.initNoFlyCircle(defaultProps);
    }
    initNoFlyCircle() {
        this.entity.drawType = NoFlyCircle.DRAWTYPE;
    }
}

export class NoFlyShowCircle extends Point {
    static DRAWTYPE = "noFlyCircle";
    constructor(options) {
        const linePoints = calculatePointForCircle(options.position, options.radius  * 1);
        options.style = {
            id: options.id,
            position: options.position,
            label: {
                text: `${options.name}`,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                backgroundPadding: new Cesium.Cartesian2(8, 4),
                font: "14px sans-serif",
                fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
                pixelOffset: new Cesium.Cartesian2(0, -32),
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 400
                )
            },
            point: {
                pixelSize: 10,
                color: Cesium.Color.WHITE,
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 2,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
            },
            ellipse: {
                semiMinorAxis: options.radius * 1,
                semiMajorAxis: options.radius * 1,
                material: Cesium.Color.fromCssColorString('rgba(0, 0, 0, 0)'),
                outline: false,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
            },
            polyline: {
                positions: linePoints,
                width: options.outLineWidth || 1,
                material: Cesium.Color.fromCssColorString(options.outLineColor || '#D84A31'),
                clampToGround: true
            },
            polygon: {
                hierarchy: new Cesium.PolygonHierarchy(linePoints),
                material: Cesium.Color.fromCssColorString(options.overlayColor || '#D84A31')
                    .withAlpha(options.overlayAlpha || 0.45),
                fill: true,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
            }
        };
        let defaultProps = Object.assign({
            category: NodeMap.Node.NOFLYZONE,
            show: true
        }, options);
        super(defaultProps);
        this._drawType = NoFlyShowCircle.DRAWTYPE;
        this._noFlyType = defaultProps.noFlyType;
        this._canEdit = false;
    }
    get noFlyType() {
        return this._noFlyType;
    }
    get drawType() {
        return this._drawType;
    }
}