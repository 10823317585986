import Line from "../../base/Line";
import { getNavigationLineStyle } from "../../../style/index";
import {
  NavigationLineStartNode,
  NavigationLineEndNode,
} from "./NavigationLineStartEndNode";

export default class NavigationLine extends Line {
  static DRAWTYPE = 'NavigationLine'
  constructor(options) {
    let style = options.style
      ? options.style
      : getNavigationLineStyle({
          positions: options.positions,
          name: options.name,
          zIndex: options.zIndex ? options.zIndex : 0,
        });
    options.style = style;
    super(options);
    this._drawType = NavigationLine.DRAWTYPE
    this._nodes = [];
    this.initNavigationLine(options);
  }

  get nodes() {
    return this._nodes;
  }

  get drawType() {
    return this._drawType
  }

  initNavigationLine(options) {
    // TODO 创建两个label 开始ihe结束
    const start = new NavigationLineStartNode({
      id: Cesium.createGuid() + "-Navigation",
      position: options.positions[0],
      root: this,
      parent: this,
      show: options.show !== false,
    });
    const end = new NavigationLineEndNode({
      id: Cesium.createGuid() + "-Navigation",
      position: options.positions[options.positions.length - 1],
      root: this,
      parent: this,
      show: options.show !== false,
    });
    this.addNode(start);
    this.addNode(end);
  }
  addNode(node) {
    this._nodes.push(node);
  }
}
