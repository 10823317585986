import MarkLine from '../../mark/MarkLine'
import NodeMap from "../../index"
import { getPowerLineStyle } from "../../../style/index";
import PLP from "@/assets/img/map/oilPowerLinePoint.png";

export default class PowerLine extends MarkLine {
  static DRAWTYPE = "oilPowerLine";
  constructor(options) {
    options = Object.assign({
      color: "#FF5050"
    }, options)
    let defaultProps = Object.assign({
      style: getPowerLineStyle({
        positions: options.positions,
        name: options.name
      }),
      category: NodeMap.Node.INSPECTION
    }, options)
    defaultProps.inflectionImage = PLP
    super(defaultProps);
    this._drawType = PowerLine.DRAWTYPE
    this.initPowerLine(defaultProps);
  }

  initPowerLine() {
    this._entity.drawType = PowerLine.DRAWTYPE;
  }
}