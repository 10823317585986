const getRouteLineStyle = (options) => {
    return {
        position: options.positions[0],
        polyline: {
            width: 3,
            material: new Cesium.Color.fromCssColorString("#EC3FF7"),
            positions: options.positions,
            // 航线不一定贴地
            clampToGround: true,
            zIndex: options.zIndex? options.zIndex: 0,
        }
    };
};

const getRouteStyle = (options) => {
    return {
      position: options.positions[0],
      label: {
        text: `${options.name}`,
        showBackground: true,
        backgroundColor: Cesium.Color.fromCssColorString(options.color),
        backgroundPadding: new Cesium.Cartesian2(8, 4),
        font: "14px sans-serif",
        fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
        pixelOffset: new Cesium.Cartesian2(0, -32),
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 1000 * 400),
      },
    };
  };

export  {
    getRouteLineStyle,
    getRouteStyle
}
