import HP from "@/assets/map/homePoint.png";
import PC from "@/assets/img/map/projectCenter.png";
const getHomePointStyle = (label) => {
  return {
    point: {
      show: false,
      pixelSize: 10,
      color: Cesium.Color.fromCssColorString("#FFFFFF"),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 1000 * 2.8
      )
    },
    label: {
      text: `${label}`,
      scale: 0.5,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
      backgroundPadding: new Cesium.Cartesian2(16, 8),
      font: "28px sans-serif",
      pixelOffset: new Cesium.Cartesian2(0, 32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 50
      ),
    },
    billboard: {
      image: HP,
      width: 32,
      height: 32,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 1000 * 2.8
      ),
    }
  };
};

const getProjectCenterPointStyle = (label) => {
  return {
    billboard: {
      image: PC,
      width: 32,
      height: 40,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
    }
  };
};

export {
  getHomePointStyle,
  getProjectCenterPointStyle
};