import MarkPoint from "../../mark/MarkPoint";
import NodeMap from "../../index";
import station from "@/assets/img/map/station.png";
import stationActive from "@/assets/img/map/station-active.png";

export default class BaseStationPoint extends MarkPoint {
    static DRAWTYPE = "baseStationPoint";
    constructor(options) {
        let defaultProps = Object.assign({
            category: NodeMap.Node.BASESTATION,
            icon: station,
            iconHover: stationActive,
            iconSelecte: stationActive
        }, options);
        defaultProps.style = {
            label: {
                text: `${defaultProps.name}`,
                scale: 0.5,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                backgroundPadding: new Cesium.Cartesian2(16, 8),
                font: "28px sans-serif",
                pixelOffset: new Cesium.Cartesian2(0, 32),
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 50
                )
            },
            billboard: {
                image: defaultProps.icon,
                width: 30,
                height: 40,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 1000 * 2.8
                )
            }
        };
        super(defaultProps);
        this._drawType = BaseStationPoint.DRAWTYPE;
        this._canEdit = false;
        this._selected = false;
        this.initBaseStationPoint(defaultProps);
    }
    initBaseStationPoint() {
        this.entity.drawType = BaseStationPoint.DRAWTYPE;
    }
}