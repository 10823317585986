import NodeMap from "../../index"
import MarkLine from '../../mark/MarkLine'
import { getOilPiepelinetStyle } from "../../../style/index";
export default class OilPipeline extends MarkLine {
  static DRAWTYPE = "oilLine";
  constructor(options) {
    options = Object.assign({
      color: "#00AEE2"
    }, options)
    let defaultProps = Object.assign({
      style: getOilPiepelinetStyle({
        positions: options.positions,
        name: options.name
      }),
      category: NodeMap.Node.INSPECTION
    }, options)
    super(defaultProps);
    this._drawType = OilPipeline.DRAWTYPE
    this.initOilPipeline(defaultProps);
  }

  initOilPipeline(defaultProps) {
    this._entity.drawType = OilPipeline.DRAWTYPE;
  }
}