import Polygon from '../../base/Polygon'
import NodeMap from "../../index"
import { getOperationAreaStyle } from "../../../style/index";

class OperationArea extends Polygon {
  static DRAWTYPE = "OperationArea";
  static ALPHA = "33";
  static INFLECRTION_NAME = '-Inflection-Point-OperationArea'
  constructor(options) {
    let defaultProps = Object.assign({
      category: NodeMap.Node.OPERATIONAREA,
      color: "#66CC00"
    }, options)
    defaultProps.style = getOperationAreaStyle(defaultProps)
    super(defaultProps)
    this._color = defaultProps.color
    this._drawType = OperationArea.DRAWTYPE
    this._createBy = defaultProps.createBy ? defaultProps.createBy : "";
    this._selected = false
    this._canEdit = false
    this._show = defaultProps.show
    this.initOperationArea(defaultProps)
  }
  get color() {
    return this._color
  }

  set color (color) {
    this._color = color
  }

  initOperationArea(defaultProps) {
    this._entity.drawType = OperationArea.DRAWTYPE;
    this.entity.polyline =new Cesium.PolylineGraphics({
      width: 3,
      material: new Cesium.PolylineOutlineMaterialProperty  ({
        color: Cesium.Color.fromCssColorString(defaultProps.color),
        outlineColor: Cesium.Color.fromCssColorString("#FFFFFF"),
        outlineWidth: 0
      }),
      positions: this.positions.concat(this.positions[0]),
      clampToGround: true,
    })
    this.entity.polygon =new Cesium.PolygonGraphics ({
      show: defaultProps.show,
      material: Cesium.Color.fromCssColorString(this.color + OperationArea.ALPHA),
      hierarchy: new Cesium.PolygonHierarchy(this.positions)
    })
  }
}



export default OperationArea;