import Line from "../base/Line";
import MarkInflectionPoint from "./MarkInflectionPoint";
import NodeMap from "../index"
import { getMarkLineStyle } from "../../style/index";
import { getSegmentInfo } from "@/util/map";
export default class MarkLine extends Line {
  static DRAWTYPE = "MarkLine";
  static INFLECRTION_NAME = '-Inflection-Point-MarkLine'
  constructor(options) {
    const color = options.color? options.color: "#0096FF"
    let defaultProps = Object.assign({
      category: NodeMap.Node.MARK,
    }, options)
    defaultProps.color = color
    defaultProps.style = defaultProps.style? defaultProps.style: getMarkLineStyle(defaultProps);
    super(defaultProps);
    this._inflectionImage = defaultProps.inflectionImage
    this._color = defaultProps.color
    this._drawType = MarkLine.DRAWTYPE
    this._createBy = defaultProps.createBy ? defaultProps.createBy : "";
    this._nodes = [];
    this._heighLight = false
    this._selected = false
    this._canEdit = typeof defaultProps.canEdit === 'boolean' ? defaultProps.canEdit : true
    this._activateAnimation = false
    this._hoverTimer = null
    this.initMarkLine(defaultProps);
  }

  get color() {
    return this._color
  }

  set color(value) {
    this.entity.polyline.material.color = new Cesium.Color.fromCssColorString(value)
    this.entity.label.backgroundColor = new Cesium.Color.fromCssColorString(value)
    const nodes = this.getInflectionPoint()
    for (var i = 0; i < nodes.length; i++) {
      nodes[i].color = value
    }
    this._color = value
  }

  get selected() {
    return this._selected
  }

  set selected(value) {
    this.entity.polyline.material.outlineWidth = value? 3: 0
    this.entity.polyline.width = value? 8: 5
    this._selected = value
  }

  get positions() {
    return this._positions;
  }

  set hovering(value) {
    if (!this.selected) {
      if (value) {
        if (!this._hoverTimer) {
          this._hoverTimer = setTimeout(()=>{
            this.entity.polyline.material.outlineWidth = 3
            this.entity.polyline.width = 8
            clearTimeout(this._hoverTimer)
            this._hoverTimer = null
          }, 200)
        }
      } else {
        if (this._hoverTimer) {
          clearTimeout(this._hoverTimer)
          this._hoverTimer = null
        }
        if (this.entity.polyline.material.outlineWidth._value !== 0) {
          this.entity.polyline.material.outlineWidth = 0
        }
        if (this.entity.polyline.width._value !== 5) {
          this.entity.polyline.width = 5
        }
      }
    }
     this._hovering = value
  }

  set positions(positions) {
    try {
      for (let i = 0; i < positions.length; i++) {
        const element = positions[i];
        if (!element instanceof Cesium.Cartesian3) {
          throw "positions的元素为Cesium.Cartesian3类型";
        }
      }
      let points = this.getInflectionPoint();
      // 更新line的label位置
      this._entity.position = positions[0];

      // 更新节点位置
      for (let index = 0; index < positions.length; index++) {
        const position = positions[index];
        points[index].position = position;
      }
      let horiDistance = 0;
      for (var i = 0; i < positions.length; i++) {
        // 增加两点距离的label
        if (i > 0) {
          const info = getSegmentInfo(undefined, positions[i - 1], positions[i]);
          horiDistance = horiDistance + info.length;
        }
      }
      this._entity.horiDistance = horiDistance;
      this._positions = positions;
      if (!this.activateAnimation) {
        // 更新line的位置
        this._entity.polyline.positions = positions;
      }
    } catch (error) {
      console.error(error);
    }
  }

  set name(val) {
    this._name = val;
    this._entity.name = val;
    this._entity.label.text._value = val;
  }

  get name() {
    return this._name
  }

  get createBy() {
    return this._createBy;
  }

  set createBy(val) {
    this._createBy = val;
  }

  set show(value) {
    try {
      if (typeof value === "boolean") {
        this._entity.show = value;
        this._show = value;
        for (let i = 0; i < this._nodes.length; i++) {
          let node = this._nodes[i];
          node.show = value;
        }
      } else {
        throw Error("show必须是Boolean类型");
      }
    } catch (error) {
      console.error(error);
    }
  }

  get nodes() {
    return this._nodes;
  }

  get drawType() {
    return this._drawType
  }

  get heighLight() {
    return this._heighLight
  }

  set heighLight(value) {
    this._heighLight = value
  }

  get canEdit () {
    return this._canEdit
  }

  get inflectionImage() {
    return this._inflectionImage
  }

  set inflectionImage(value) {
    this._inflectionImage = value
  }

  get activateAnimation() {
    return this._activateAnimation
  }

  set activateAnimation(value) {
    this._activateAnimation = value
  }

  initMarkLine(defaultProps) {
    // 初始化折线节点
    this.initPoints(defaultProps);
    this.entity.polyline =new Cesium.PolylineGraphics({
      width: 5,
      material: new Cesium.PolylineOutlineMaterialProperty  ({
        color: Cesium.Color.fromCssColorString(defaultProps.color),
        outlineColor: Cesium.Color.fromCssColorString("#FFFFFF"),
        outlineWidth: 0
      }),
      positions: this.positions,
      clampToGround: true,
    })
    this._entity.canEdit = true;
    this._entity.drawType = MarkLine.DRAWTYPE;
  }

  initPoints(defaultProps) {
    let horiDistance = 0;
    
    for (var i = 0; i < this.positions.length; i++) {
      // 增加折线节点
      const position = this.positions[i];
      let show = false;
      if (i === 0 || i === this.positions.length - 1) {
        show = this._show !== false;
      }
      var point = new MarkInflectionPoint({
        id: new Date().getTime().toString() + Cesium.createGuid(),
        position: position,
        name: this.name + MarkLine.INFLECRTION_NAME,
        index: `${i + 1}`,
        show: show,
        parent: this,
        color: defaultProps.color,
        inflectionImage: this.inflectionImage
      });
      point.entity.canEdit = true;
      point.entity.drawType = MarkLine.DRAWTYPE;
      this.addNode(point);

      // 增加两点距离的label
      let info;
      if (i > 0) {
        info = getSegmentInfo(
          undefined,
          this.positions[i - 1],
          this.positions[i]
        );
        horiDistance = horiDistance + info.length;
      }
    }
    this._entity.horiDistance = horiDistance;
  }

  initAnimation() {
    if (!this.activateAnimation) {
      this.entity.polyline.positions = new Cesium.CallbackProperty(()=>{
        return this.positions
      }, false)
      this.activateAnimation = true
    }
  }

  cancelAnimation() {
    if (this.activateAnimation) {
      this.entity.polyline.positions = this.positions
      this.activateAnimation = false
    }
  }

  getInflectionPoint() {
    let nodes = [];
    for (let i = 0; i < this.nodes.length; i++) {
      const node = this.nodes[i];
      if (node instanceof MarkInflectionPoint) {
        nodes.push(node);
      }
    }
    return nodes;
  }

  onSelect(){
    this.selected = true
    let points = this.getInflectionPoint();
    for (let index = 0; index < points.length; index++) {
      const element = points[index];
      element.onSelect();
      if (index !== 0 && index !== points.length - 1) {
        element.show = true
      }
    }
  }

  unSelect(){
    this.selected = false
    let points = this.getInflectionPoint();
    for (let index = 0; index < points.length; index++) {
      const element = points[index];
      element.unSelect();
      if (index !== 0 && index !== points.length - 1) {
        element.show = false
      }
    }
  }

  hover(){
    this.hovering = true
    let points = this.getInflectionPoint();
    for (let index = 0; index < points.length; index++) {
      const element = points[index];
      element.hover();
    }
  }

  leave(){
    this.hovering = false
    let points = this.getInflectionPoint();
    for (let index = 0; index < points.length; index++) {
      const element = points[index];
      element.leave();
    }
  }

  move(args) {
    this.positions[args.index] = args.position
    this.positions = this.positions
  }

  insertNode(args) {
    if (!args.position) {
      return;
    }
    const index = args.index;
    let positions = [];
    for (let i = 0; i < this._positions.length; i++) {
      const element = this._positions[i];
      if (i < index + 1) {
        positions[i] = element;
      } else if (i === index + 1) {
        positions[i] = args.position;
        positions[i + 1] = element;
      } else {
        positions[i + 1] = element;
      }
    }
    if (index === this._positions.length - 1) {
      positions.push(args.position);
    }
    // 更新line的位置
    this._entity.polyline.positions = positions;
    // // 更新line的label位置
    this._entity.position = positions[0];
    this._positions = positions;
    let points = this.getInflectionPoint();

    var editLayer = this._entity.editLayer;
    // 更新节点位置
    for (let i = 0; i < positions.length; i++) {
      var position = positions[i];
      var point = points[i];
      if (point) {
        point.position = position;
      } else {
        point = new MarkInflectionPoint({
          id: new Date().getTime().toString(),
          position: position,
          name: this.name + MarkLine.INFLECRTION_NAME,
          index: `${i + 1}`,
          show: true,
          parent: this,
          color: this.color,
          selected: true,
          inflectionImage: this.inflectionImage
        });
        point.entity.canEdit = true;
        point.entity.drawType = MarkLine.DRAWTYPE;
        editLayer.add(point.entity);
        this.addNode(point);
      }
    }
    let horiDistance = 0;
    for (var i = 0; i < positions.length; i++) {
      // 增加两点距离的label
      if (i > 0) {
        const info = getSegmentInfo(undefined, positions[i - 1], positions[i]);
        horiDistance = horiDistance + info.length;
      }
    }
    this._entity.horiDistance = horiDistance;
  }

  deletePointNode(args) {
    let positions = this._positions.filter((item, index) => {
      return index !== args.index;
    });
    var editLayer = this._entity.editLayer;
    // 更新line的位置
    this._entity.polyline.positions = positions;
    // // 更新line的label位置
    this._entity.position = positions[0];
    this._positions = positions;
    // 更新节点位置
    let points = this.getInflectionPoint();
    for (let i = 0; i < points.length; i++) {
      var position = positions[i];
      var point = points[i];
      if (point) {
        point.position = position;
        if (i === points.length - 2) {
          point.entity.show = true;
        }
      }
      if (i === points.length - 1) {
        editLayer.remove(point.entity);
      }
    }
    let horiDistance = 0;
    for (var i = 0; i < positions.length; i++) {
      // 增加两点距离的label
      if (i > 0) {
        const info = getSegmentInfo(undefined, positions[i - 1], positions[i]);
        horiDistance = horiDistance + info.length;
      }
    }
    this._entity.horiDistance = horiDistance;
    this._nodes.length--;
  }

  addNode(node) {
    this._nodes.push(node);
  }
}
