import Polygon from "../base/Polygon";
import MarkInflectionPoint from "./MarkInflectionPoint";
import NodeMap from "../index"
import { getMarkPolygonStyle } from "../../style/index";
import { getSegmentInfo } from "@/util/map";
export default class MarkPolygon extends Polygon {
  static DRAWTYPE = "MarkPolygon";
  static ALPHA = "33";
  static INFLECRTION_NAME = '-Inflection-Point-MarkPolygon'
  constructor(options) {
    const color = options.color? options.color: "#0096FF"
    let defaultProps = Object.assign({
      category: NodeMap.Node.MARK,
    }, options)
    defaultProps.color = color
    defaultProps.style = defaultProps.style? defaultProps.style: getMarkPolygonStyle(defaultProps);
    super(defaultProps);
    this._inflectionImage = defaultProps.inflectionImage
    this._color = defaultProps.color
    this._drawType = MarkPolygon.DRAWTYPE
    this._createBy = defaultProps.createBy ? defaultProps.createBy : "";
    this._nodes = [];
    this._heighLight = false
    this._selected = false
    this._canEdit = typeof defaultProps.canEdit === 'boolean' ? defaultProps.canEdit : true
    this._show = defaultProps.show
    this._activateAnimation = false
    this.initMarkPolygon(defaultProps);
  }

  get color() {
    return this._color
  }

  set color(value) {
    this.entity.polyline.material.color = new Cesium.Color.fromCssColorString(value)
    this.entity.label.backgroundColor = new Cesium.Color.fromCssColorString(value)
    this.entity.polygon.material = Cesium.Color.fromCssColorString(value + MarkPolygon.ALPHA)
    const nodes = this.getInflectionPoint()
    for (var i = 0; i < nodes.length; i++) {
      nodes[i].color = value
    }
    this._color = value
  }

  get selected() {
    return this._selected
  }

  set selected(value) {
    if (this._selected !== value) {
      this.entity.polyline.material.outlineWidth = value? 3: 0
      this.entity.polyline.width = value? 8: 5
      this._selected = value
    }
  }
  
  set hovering(value) {
    if (this._hovering !== value && !this.selected) {
      this.entity.polyline.material.outlineWidth = !value && !this.selected? 0: 3
      this.entity.polyline.width = !value && !this.selected? 5: 8
      this._hovering = value
    }
  }

  get positions() {
    return this._positions;
  }

  set positions(positions) {
    try {
      for (let i = 0; i < positions.length; i++) {
        const element = positions[i];
        if (!element instanceof Cesium.Cartesian3) {
          throw "positions的元素为Cesium.Cartesian3类型";
        }
      }
      let points = this.getInflectionPoint();
      // 更新polygon的label位置
      this._entity.position = positions[0];

      // 更新节点位置
      for (let index = 0; index < positions.length; index++) {
        const position = positions[index];
        points[index].position = position;
      }
      let horiDistance = 0;
      for (var i = 0; i < positions.length; i++) {
        // 增加两点距离的label
        if (i === 0) {
          var info = getSegmentInfo(undefined, positions[positions.length - 1], positions[i]);
          horiDistance = horiDistance + info.length;
        } else {
          var info = getSegmentInfo(undefined, positions[i - 1], positions[i]);
          horiDistance = horiDistance + info.length;
        }
      }
      this._entity.horiDistance = horiDistance;
      this._positions = positions;
      if (!this.activateAnimation) {
        this.entity.polygon.hierarchy = new Cesium.PolygonHierarchy(this.positions)
        this.entity.polyline.positions = this.positions.concat(this.positions[0])
      }
    } catch (error) {
      console.error(error);
    }
  }

  set name(val) {
    this._name = val;
    this._entity.name = val;
    this._entity.label.text._value = val;
  }

  get name() {
    return this._name
  }

  get createBy() {
    return this._createBy;
  }

  set createBy(val) {
    this._createBy = val;
  }

  set show(value) {
    try {
      if (typeof value === "boolean") {
        this._entity.show = value;
        this._show = value;
        for (let i = 0; i < this._nodes.length; i++) {
          let node = this._nodes[i]
          node.show = value;
        }
      } else {
        throw Error("show必须是Boolean类型");
      }
    } catch (error) {
      console.error(error);
    }
  }

  get nodes() {
    return this._nodes;
  }

  get drawType() {
    return this._drawType
  }

  get heighLight() {
    return this._heighLight
  }

  set heighLight(value) {
    this._heighLight = value
  }

  get canEdit () {
    return this._canEdit
  }

  get inflectionImage() {
    return this._inflectionImage
  }

  set inflectionImage(value) {
    this._inflectionImage = value
  }

  get activateAnimation() {
    return this._activateAnimation
  }

  set activateAnimation(value) {
    this._activateAnimation = value
  }

  initMarkPolygon(defaultProps) {
    // 初始化折线节点
    this.initPoints(defaultProps);
    this.entity.polyline =new Cesium.PolylineGraphics({
      width: 5,
      material: new Cesium.PolylineOutlineMaterialProperty  ({
        color: Cesium.Color.fromCssColorString(defaultProps.color),
        outlineColor: Cesium.Color.fromCssColorString("#FFFFFF"),
        outlineWidth: 0
      }),
      clampToGround: true,
    })
    this.entity.polygon =new Cesium.PolygonGraphics ({
      show: defaultProps.show,
      material: Cesium.Color.fromCssColorString(this.color + MarkPolygon.ALPHA),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
    })
    this.initState();
    this._entity.canEdit = true;
    this._entity.drawType = MarkPolygon.DRAWTYPE;
  }

  initPoints(defaultProps) {
    let horiDistance = 0;
    for (var i = 0; i < this.positions.length; i++) {
      // 增加折线节点
      const position = this.positions[i];
      var point = new MarkInflectionPoint({
        id: new Date().getTime().toString() + Cesium.createGuid(),
        position: position,
        name: this.name + MarkPolygon.INFLECRTION_NAME,
        index: `${i + 1}`,
        show: false,
        parent: this,
        color: defaultProps.color,
        inflectionImage: this.inflectionImage
      });
      point.entity.canEdit = true;
      point.entity.drawType = MarkPolygon.DRAWTYPE;
      this.addNode(point);

      // 增加两点距离的label
      let info;
      if (i > 0) {
        info = getSegmentInfo(undefined, this.positions[i - 1], this.positions[i]);
        horiDistance = horiDistance + info.length;
      } else {
        info = getSegmentInfo(undefined, this.positions[this.positions.length - 1], this.positions[0]);
        horiDistance = horiDistance + info.length;
      }
    }
    this._entity.horiDistance = horiDistance;
  }

  initState() {
    this.entity.polyline.material.outlineWidth = 0
    this.entity.polyline.width = 5
    this.entity.polygon.hierarchy = new Cesium.PolygonHierarchy(this.positions)
    this.entity.polyline.positions = this.positions.concat(this.positions[0])
  }

  initAnimation() {
    if (!this.activateAnimation) {
      this.entity.polygon.hierarchy = new Cesium.CallbackProperty(()=>{
        return new Cesium.PolygonHierarchy(this.positions)
      }, false)
      this.entity.polyline.positions = new Cesium.CallbackProperty(()=>{
        return this.positions.concat(this.positions[0])
      }, false)
      this.activateAnimation = true
    }
  }

  cancelAnimation() {
    if (this.activateAnimation) {
      this.entity.polygon.hierarchy = new Cesium.PolygonHierarchy(this.positions)
      this.entity.polyline.positions = this.positions.concat(this.positions[0])
      this.activateAnimation = false
    }
  }

  getInflectionPoint() {
    let nodes = [];
    for (let i = 0; i < this.nodes.length; i++) {
      const node = this.nodes[i];
      if (node instanceof MarkInflectionPoint) {
        nodes.push(node);
      }
    }
    return nodes;
  }

  onSelect(){
    this.selected = true
    let points = this.getInflectionPoint();
    for (let index = 0; index < points.length; index++) {
      const element = points[index];
      element.onSelect();
      element.show = true
    }
  }

  unSelect(){
    this.selected = false
    let points = this.getInflectionPoint();
    for (let index = 0; index < points.length; index++) {
      const element = points[index];
      element.unSelect();
      element.show = false
      // if (index !== 0 && index !== points.length - 1) {
      //   element.show = false
      // }
    }
  }

  hover(){
    this.hovering = true
    let points = this.getInflectionPoint();
    for (let index = 0; index < points.length; index++) {
      const element = points[index];
      element.hover();
    }
  }

  leave(){
    this.hovering = false
    let points = this.getInflectionPoint();
    for (let index = 0; index < points.length; index++) {
      const element = points[index];
      element.leave();
    }
  }

  move(args) {
    this.positions[args.index] = args.position
    this.positions = this.positions
  }

  insertNode(args) {
    if (!args.position) {
      return;
    }
    const index = args.index;
    let positions = [];
    // 将新加点插入指定位置
    for (let i = 0; i < this.positions.length; i++) {
      const element = this.positions[i];
      if (i < index + 1) {
        positions[i] = element;
      } else if (i === index + 1) {
        positions[i] = args.position;
        positions[i + 1] = element;
      } else {
        positions[i + 1] = element;
      }
    }
    if (index === this._positions.length - 1) {
      positions.push(args.position);
    }
    // 创建最新拐点
    let point = new MarkInflectionPoint({
      id: new Date().getTime().toString(),
      position: args.position,
      name: this.name + MarkPolygon.INFLECRTION_NAME,
      index: positions.length,
      show: true,
      parent: this,
      color: this.color,
      selected: true,
      inflectionImage: this.inflectionImage
    });
    point.entity.canEdit = true;
    point.entity.drawType = MarkPolygon.DRAWTYPE;
    this.addNode(point);
    let editLayer = this._entity.editLayer;
    editLayer.add(point.entity);
    this.positions = positions;
  }

  deletePointNode(args) {
    let positions = this._positions.filter((item, index) => {
      return index !== args.index;
    });
    var editLayer = this.entity.editLayer;
    let inflectionPoint = this.nodes[this.nodes.length - 1]
    this.removeNodeById(inflectionPoint.id)
    editLayer.remove(inflectionPoint.entity)
    this.positions = positions
  }

  addNode(node) {
    this._nodes.push(node);
  }

  removeNodeById(id) {
    this._nodes = this._nodes.filter(item=>{
      return id !== item.id
    })
  }
}
