import MarkPoint from "../../mark/MarkPoint";
import NodeMap from "../../index"
import oilStationStorage from "@/assets/img/map/oilStationStorage.png";
import oilStationStorageActive from "@/assets/img/map/oilStationStorage-active.png";
import { getStationStorageStyle } from "../../../style/index";

export default class StationStorage extends MarkPoint {
  static DRAWTYPE = "oilStationStorage";
  constructor(options) {
    let defaultProps = Object.assign({
      category: NodeMap.Node.INSPECTION,
      icon: oilStationStorage,
      iconHover: oilStationStorageActive,
      iconSelecte: oilStationStorageActive,
    }, options)
    defaultProps.style = getStationStorageStyle(defaultProps);
    super(defaultProps);
    this._drawType = StationStorage.DRAWTYPE
    this._hover = null;
    this.initStationStorage(defaultProps);
  }

  initStationStorage() {
    this.entity.canEdit = true;
    this.entity.drawType = StationStorage.DRAWTYPE;
  }
}
