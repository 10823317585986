import start from "@/assets/img/map/start2.png";
import end from "@/assets/img/map/end.png";
const getNavigationLineStyle = (options) => {
    return {
        position: options.positions[0],
        polyline: {
            width: 3,
            material: new Cesium.Color.fromCssColorString("#409EFF"),
            positions: options.positions,
            clampToGround: true,
            zIndex: options.zIndex? options.zIndex: 0,
        }
    };
};

const getNavigationLineStartStyle = (options) => {
    const size = 32
    return {
        position: options.position,
        billboard: {
            image: start,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            width: size,
            height: size * 4/3,
            pixelOffset: new Cesium.Cartesian2(0, -size * 4/3 / 2),
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        }
    };
};

const getNavigationLineEndStyle = (options) => {
    const size = 32
    return {
        position: options.position,
        billboard: {
            image: end,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            width: size,
            height: size * 4/3,
            pixelOffset: new Cesium.Cartesian2(0, -size * 4/3 / 2),
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
          }
    };
};



export  {
    getNavigationLineStyle,
    getNavigationLineStartStyle,
    getNavigationLineEndStyle
}
