/*
 * @Description: 
 * @Autor: wq
 * @Date: 2022-07-12 17:00:03
 * @watermark: 成都傲势科技
 * @LastEditors: wq
 */
import GeoTerrainProvider from "./GeoTerrainProvider";

export const distances = [
  1, 2, 3, 5, 10, 20, 30, 50, 100, 200, 300, 500, 1000, 2000, 3000, 5000, 10000,
  20000, 30000, 50000, 100000, 200000, 300000, 500000, 1000000, 2000000,
  3000000, 5000000, 10000000, 20000000, 30000000, 50000000,
];
export const tdtUrl = "https://t{s}.tianditu.gov.cn";

export const baseLayer = [{name: '天地图基础影像', id: 'baseImg', checked: true}, {name: '天地图基础标注', id:  'baseCia', checked: true}];
export const terrain = [{name: '天地图基础地形', id: 'baseTerrain', checked: false}]

// 服务负载子域
const subdomains = ["0", "1", "2", "3", "4", "5", "6", "7"];


export const getTerrainProvider = () => {
  
  const terrainUrls = new Array();

  for (let i = 0; i < subdomains.length; i++) {
    let url = tdtUrl.replace("{s}", subdomains[i]) +"/mapservice/swdx?T=elv_c&tk=7f3a6463a4e8b66fbfb22a3b78a9c710";
    // let url = tdtUrl.replace("{s}", subdomains[i]) +"/DataServer?T=elv_c&tk=7f3a6463a4e8b66fbfb22a3b78a9c710";
    terrainUrls.push(url);
  }

  let provider = new GeoTerrainProvider({
    urls: terrainUrls,
  });
  provider.name = "baseTerrain";
  return provider;
};

export const colors = [
  '#0096FF',
  '#FF8900',
  '#FF4443',
  '#A94BFF',
  '#8CD51C'
]
