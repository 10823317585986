import MarkLine from '../../mark/MarkLine'
import NodeMap from "../../index"
import { getOilRoadStyle } from "../../../style/index";
export default class OilRoad extends MarkLine {
  static DRAWTYPE = "oilRoad";
  constructor(options) {
    options = Object.assign({
      color: "#C4136A"
    }, options)
    let defaultProps = Object.assign({
      style: getOilRoadStyle({
        positions: options.positions,
        name: options.name
      }),
      category: NodeMap.Node.INSPECTION
    }, options)
    super(defaultProps);
    this._drawType = OilRoad.DRAWTYPE
    this.initRoad(defaultProps);
  }

  initRoad() {
    this._entity.drawType = OilRoad.DRAWTYPE;
  }
  initState() {
    this.entity.polyline.material.outlineWidth = 3
    this.entity.polyline.width = 8
  }
}