import NodeMap from "../../index";
import MarkPoint from "../../mark/MarkPoint";
import warningPoint from "@/assets/img/map/warning.png";
import warningPointActive from "@/assets/img/map/warning-active.png";
import { getWarningPointStyle } from "../../../style/index";

export default class WarningPoint extends MarkPoint {
    static DRAWTYPE = 'WarningPoint'
    constructor(options){
        let defaultProps = Object.assign({
            category: NodeMap.Node.WARNING,
            icon: options.icon || warningPoint,
            iconHover: options.selectIcon || warningPointActive,
            iconSelecte: options.selectIcon || warningPointActive,
        }, options)
        defaultProps.style = getWarningPointStyle(defaultProps);
        super(defaultProps);
        this._drawType = WarningPoint.DRAWTYPE
        this.initWarningPoint(options)
    }

    initWarningPoint() {
        this._canEdit = false
        this.entity.drawType = WarningPoint.DRAWTYPE;
    }
}
  