import Point from "../../base/Point";
import { getProjectCenterPointStyle } from "../../../style/index";

export default class ProjectCenterPoint extends Point {
    static DRAWTYPE = 'ProjectCenterPoint'
    constructor(options){
        let style = options.style? options.style: getProjectCenterPointStyle(options.name)
        options.style = style
        super(options)
        this._drawType = ProjectCenterPoint.DRAWTYPE
        this._createBy = options.createBy? options.createBy: ''
        this._hover = null
        this.initProjectCenterPoint(options)
    }

    get createBy() {
        return this._createBy
    }

    set createBy(val) {
        this._createBy = val
    }
    
    initProjectCenterPoint() {
        this.entity.canEdit = true
        this.entity.drawType = ProjectCenterPoint.DRAWTYPE
    }

    toHeighLight() {}

    cancelHeighLight() {}
}