const getOilWellPointStyle = (args) => {
  return {
    label: {
      text: `${args.name}`,
      scale: 0.5,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
      backgroundPadding: new Cesium.Cartesian2(16, 8),
      font: "28px sans-serif",
      pixelOffset: new Cesium.Cartesian2(0, 32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 50
      ),
    },
    billboard: {
      image: args.icon,
      width: 48,
      height: 48,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 1000 * 2.8
      ),
    },
  };
}

const getOilGasWellPointStyle = (args) => {
  return {
    label: {
      text: `${args.name}`,
      scale: 0.5,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
      backgroundPadding: new Cesium.Cartesian2(16, 8),
      font: "28px sans-serif",
      pixelOffset: new Cesium.Cartesian2(0, 32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 50
      ),
    },
    billboard: {
      image: args.icon,
      width: 48,
      height: 48,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 1000 * 2.8
      ),
    },
  };
};

const getStorageTankStyle = (args) => {
  return {
    label: {
      text: `${args.name}`,
      scale: 0.5,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
      backgroundPadding: new Cesium.Cartesian2(16, 8),
      font: "28px sans-serif",
      pixelOffset: new Cesium.Cartesian2(0, 32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 50
      ),
    },
    billboard: {
      image: args.icon,
      width: 48,
      height: 48,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 1000 * 2.8
      ),
    },
  };
};

const getMeasurementStationStyle = (args) => {
  return {
    label: {
      text: `${args.name}`,
      scale: 0.5,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
      backgroundPadding: new Cesium.Cartesian2(16, 8),
      font: "28px sans-serif",
      pixelOffset: new Cesium.Cartesian2(0, 32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 50
      ),
    },
    billboard: {
      image: args.icon,
      width: 48,
      height: 48,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 1000 * 2.8
      ),
    },
  };
};

const getStationStorageStyle = (args) => {
  return {
    label: {
      text: `${args.name}`,
      scale: 0.5,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
      backgroundPadding: new Cesium.Cartesian2(16, 8),
      font: "28px sans-serif",
      pixelOffset: new Cesium.Cartesian2(0, 32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 50
      ),
    },
    billboard: {
      image: args.icon,
      width: 48,
      height: 48,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 1000 * 2.8
      ),
    },
  };
};

const getPowerEquipmentStyle = (args) => {
  return {
    label: {
      text: `${args.name}`,
      scale: 0.5,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
      backgroundPadding: new Cesium.Cartesian2(16, 8),
      font: "28px sans-serif",
      pixelOffset: new Cesium.Cartesian2(0, 32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 50
      ),
    },
    billboard: {
      image: args.icon,
      width: 48,
      height: 48,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 1000 * 2.8
      ),
    },
  };
};

const getOilPiepelinetStyle = (options) => {
  return {
    position: options.positions[0],
    label: {
      text: `${options.name}`,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#00AEE2"),
      backgroundPadding: new Cesium.Cartesian2(8, 4),
      font: "14px sans-serif",
      fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
      pixelOffset: new Cesium.Cartesian2(0, -32),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 400
      ),
    },
  };
};

const getOilRoadStyle = (options) => {
  return {
    position: options.positions[0],
    polyline: {
      width: 8,
      material: new Cesium.PolylineOutlineMaterialProperty({
        color:   new Cesium.Color.fromCssColorString("#C4136A"),
        outlineColor: Cesium.Color.WHITE,
        outlineWidth: 3
      }),
      positions: options.positions,
      clampToGround: true,
    },
    label: {
      text: `${options.name}`,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#C4136A"),
      backgroundPadding: new Cesium.Cartesian2(8, 4),
      font: "14px sans-serif",
      fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
      pixelOffset: new Cesium.Cartesian2(0, -24),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 400
      ),
    },
  };
};

const getPowerLineStyle = (options) => {
  return {
    position: options.positions[0],
    polyline: {
      width: 5,
      material: new Cesium.Color.fromCssColorString("#FF5050"),
      positions: options.positions,
      clampToGround: true,
    },
    label: {
      text: `${options.name}`,
      showBackground: true,
      backgroundColor: Cesium.Color.fromCssColorString("#FF5050"),
      backgroundPadding: new Cesium.Cartesian2(8, 4),
      font: "14px sans-serif",
      fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
      pixelOffset: new Cesium.Cartesian2(0, -24),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        1000 * 400
      ),
    },
  };
};

export {
  getStorageTankStyle,
  getMeasurementStationStyle,
  getStationStorageStyle,
  getPowerEquipmentStyle,
  getOilWellPointStyle,
  getOilGasWellPointStyle,
  getOilPiepelinetStyle,
  getOilRoadStyle,
  getPowerLineStyle
};
