import MarkPoint from "../../mark/MarkPoint";
import NodeMap from "../../index"
import storageTankIcon from "@/assets/img/map/oilStorageTank.png";
import storageTankActiveIcon from "@/assets/img/map/oilStorageTank-active.png";
import { getStorageTankStyle } from "../../../style/index";

export default class StorageTank extends MarkPoint {
  static DRAWTYPE = "oilStorageTank";
  constructor(options) {
    let defaultProps = Object.assign({
      category: NodeMap.Node.INSPECTION,
      icon: storageTankIcon,
      iconHover: storageTankActiveIcon,
      iconSelecte: storageTankActiveIcon
    }, options)
    defaultProps.style = getStorageTankStyle(defaultProps);
    super(defaultProps);
    this._drawType = StorageTank.DRAWTYPE
    this._hover = null;
    this.initOilWellPoint(defaultProps);
  }
  initOilWellPoint() {
    this.entity.canEdit = true;
    this.entity.drawType = StorageTank.DRAWTYPE;
  }
}
