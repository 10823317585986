import Point from './../../base/Point';
import MarkPoint from "../../mark/MarkPoint";
import NodeMap from "../../index";
import airport from "@/assets/img/map/airport.png";
import airportActive from "@/assets/img/map/airport-active.png";

export class AirportPoint extends MarkPoint {
    static DRAWTYPE = "airportPoint";
    constructor(options) {
        let defaultProps = Object.assign({
            category: NodeMap.Node.AIRPORT,
            icon: airport,
            iconHover: airportActive,
            iconSelecte: airportActive
        }, options);
        defaultProps.style = {
            label: {
                text: `${defaultProps.name}`,
                scale: 0.5,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                backgroundPadding: new Cesium.Cartesian2(16, 8),
                font: "28px sans-serif",
                pixelOffset: new Cesium.Cartesian2(0, 32),
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 50
                )
            },
            billboard: {
                image: defaultProps.icon,
                width: 33,
                height: 38,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 1000 * 2.8
                )
            }
        };
        super(defaultProps);
        this._drawType = AirportPoint.DRAWTYPE;
        this.initAirportPoint(defaultProps);
    }
    initAirportPoint() {
        this.entity.drawType = AirportPoint.DRAWTYPE;
    }
}

export class AirportShowPoint extends Point {
    constructor(options) {
        options.style = {
            id: options.id,
            position: options.position,
            label: {
                text: `${options.name}`,
                scale: 0.5,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                backgroundPadding: new Cesium.Cartesian2(16, 8),
                font: "28px sans-serif",
                pixelOffset: new Cesium.Cartesian2(0, 32),
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 50
                )
            },
            billboard: {
                image: airport,
                width: 33,
                height: 38,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 1000 * 2.8
                )
            }
        };
        super(options);
        this._canEdit = false;
        this._selected = false;
    }
}