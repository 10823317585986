import Node from './Node'
class Polygon extends Node {
  constructor(options) {
    options = Object.assign({}, options);
    super(options)
    try {
      if (Array.isArray() && options.positions.length < 2) {
        this.throw('postions必须为数组')
      }
    } catch (error) {
      this.catch(error)
    }
    this._positions = options.positions
    this._entity = null;
    this._type = Node.POLYGON
    this._show = options.show === false? false: options.show
    this._selected = false
    this.init(options);
  }

  init(options) {
    this._entity = new Cesium.Entity({
      id: this._id? this._id: Cesium.createGuid(),
      name: this._name,
      show: options.show,
      ...options.style
    });
    this._positions = options.positions
    this._entity.root = this
  }

  get type() {
    return this._type
  }

  get positions (){
    return this._positions
  }

  set positions (positions) {
    try {
        for (let i = 0; i < positions.length; i++) {
            const element = positions[i];
            if (!element instanceof Cesium.Cartesian3) {
                throw "positions的元素为Cesium.Cartesian3类型"
            }
        }
        // 更新line的位置
        this._entity.polyline.positions = positions
        this._positions = positions
    } catch (error) {
        console.error(error);
    }
  }

  set name(val) {
    this._entity.name = val
    this._name = val
  }
  
  get name() {
    this._name
  }

  get entity() {
    return this._entity
  }

  get root () {
    return this._entity.root
  }
  
  get show() {
      return this._show
  }

  set show(value) {
    try {
      if (typeof value === 'boolean') {
        this._entity.show = value
        this._show = value
      }else{
        throw Error('show必须是Boolean类型')
      }
    } catch (error) {
      console.error(error);
    }
  }

  getHovertipPosition (event) {
    return event.endPosition
  }

  throw(str) {
    throw('Line类型'+ str)
  }

  catche(err) {
    console(err)
  }
}
export default Polygon;
  