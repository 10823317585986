import Node from './Node'
class Point extends Node {
  constructor(options) {
    options = Object.assign({}, options);
    super(options)
    this._position = options.position
    this._type = Node.POINT
    this._show = options.show === false? false: options.show
    this._entity = null
    this.init(options);
  }

  init(options) {
    this._entity = new Cesium.Entity({
        id: this._id? this._id: Cesium.createGuid(),
        position: this._position,
        show: options.show,
        ...options.style,
    });
    this._entity.root = this
  }
  get type() {
    return this._type
  }
  get position(){
    return this._position
  }
  set position(val) {
    try {
      if (!val instanceof Cesium.Cartesian3) {
        throw "position应为Cesium.Cartesian3类型"
      }
      this._entity.position = val
      this._position = val
    } catch (error) {
      console.error(error);
    }
  }
  get entity() {
    return this._entity
  }
  get root () {
    return this._entity.root
  }
  get show() {
      return this._show
  }
  set show(value) {
    try {
      if (typeof value === 'boolean') {
        this._entity.show = value
        this._show = value
      }else{
        throw Error('show必须是Boolean类型')
      }
    } catch (error) {
      console.error(error);
    }
  }
  
  getHovertipPosition (event) {
    const scene = this.entity.editLayer.layerManager._map.scene
    return Cesium.SceneTransforms.wgs84ToWindowCoordinates(scene, this._position)
  }
}
export default Point;
