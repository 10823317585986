import MarkPoint from "../../mark/MarkPoint";
import NodeMap from "../../index";
import warningPoint from "@/assets/img/map/oilWarning.png";
import warningPointActive from "@/assets/img/map/oilWarning-active.png";

export default class OilScreenWarningPoint extends MarkPoint {
    static DRAWTYPE = "oilScreenWarningPoint";
    constructor(options) {
        let defaultProps = Object.assign({
            category: NodeMap.Node.WARNING,
            icon: options.icon || warningPoint,
            iconHover: options.selectIcon || warningPointActive,
            iconSelecte: options.selectIcon || warningPointActive
        }, options);
        defaultProps.style = {
            billboard: {
                image: defaultProps.icon,
                width: 36,
                height: 48,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 1000 * 2.8
                )
            }
        };
        super(defaultProps);
        this._drawType = OilScreenWarningPoint.DRAWTYPE;
        this._canEdit = false;
        this._selected = false;
        this.initOilScreenWarningPoint(defaultProps);
    }
    initOilScreenWarningPoint() {
        this.entity.drawType = OilScreenWarningPoint.DRAWTYPE;
    }
}