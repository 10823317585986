import NodeMap from "../../index"
import MarkLine from '../../mark/MarkLine'
import { getRouteStyle } from "../../../style/index";
import RouteInflectionPoint from "./RouteInflectionPoint";
import { getSegmentInfo } from "@/util/map";

export default class Route extends MarkLine {
  static INFLECRTION_NAME = '-Inflection-Point-Route'
  static DRAWTYPE = "route";
  constructor(options) {
    options = Object.assign({
      color: "#FFAA00"
    }, options)
    let defaultProps = Object.assign({
      style: getRouteStyle({
        color: options.color,
        positions: options.positions,
        name: options.name
      }),
      category: NodeMap.Node.ROUTE
    }, options)
    super(defaultProps);
    this._drawType = Route.DRAWTYPE
    this.initRoute(defaultProps);
  }

  initRoute(defaultProps) {
    this._entity.drawType = Route.DRAWTYPE;
  }

  initPoints(defaultProps) {
    let horiDistance = 0;
    
    for (var i = 0; i < this.positions.length; i++) {
      // 增加折线节点
      const position = this.positions[i];
      let show = false;
      if (i === 0 || i === this.positions.length - 1) {
        show = this._show !== false;
      }
      var point = new RouteInflectionPoint({
        id: new Date().getTime().toString() + Cesium.createGuid(),
        position: position,
        name: this.name + Route.INFLECRTION_NAME,
        index: `${i + 1}`,
        show: show,
        parent: this,
        color: defaultProps.color,
        inflectionImage: this.inflectionImage
      });
      point.entity.canEdit = true;
      point.entity.drawType = Route.DRAWTYPE;
      this.addNode(point);

      // 增加两点距离的label
      let info;
      if (i > 0) {
        info = getSegmentInfo(
          undefined,
          this.positions[i - 1],
          this.positions[i]
        );
        horiDistance = horiDistance + info.length;
      }
    }
    this._entity.horiDistance = horiDistance;
  }

  getInflectionPoint() {
    let nodes = [];
    for (let i = 0; i < this.nodes.length; i++) {
      const node = this.nodes[i];
      if (node instanceof RouteInflectionPoint) {
        nodes.push(node);
      }
    }
    return nodes;
  }

  insertNode(args) {
    if (!args.position) {
      return;
    }
    const index = args.index;
    let positions = [];
    for (let i = 0; i < this._positions.length; i++) {
      const element = this._positions[i];
      if (i < index + 1) {
        positions[i] = element;
      } else if (i === index + 1) {
        positions[i] = args.position;
        positions[i + 1] = element;
      } else {
        positions[i + 1] = element;
      }
    }
    if (index === this._positions.length - 1) {
      positions.push(args.position);
    }
    // 更新line的位置
    this._entity.polyline.positions = positions;
    // // 更新line的label位置
    this._entity.position = positions[0];
    this._positions = positions;
    let points = this.getInflectionPoint();

    var editLayer = this._entity.editLayer;
    // 更新节点位置
    for (let i = 0; i < positions.length; i++) {
      var position = positions[i];
      var point = points[i];
      if (point) {
        point.position = position;
      } else {
        point = new RouteInflectionPoint({
          id: new Date().getTime().toString(),
          position: position,
          name: this.name + Route.INFLECRTION_NAME,
          index: `${i + 1}`,
          show: true,
          parent: this,
          color: this.color,
          selected: true,
          inflectionImage: this.inflectionImage
        });
        point.entity.canEdit = true;
        point.entity.drawType = Route.DRAWTYPE;
        editLayer.add(point.entity);
        this.addNode(point);
      }
    }
    let horiDistance = 0;
    for (var i = 0; i < positions.length; i++) {
      // 增加两点距离的label
      if (i > 0) {
        const info = getSegmentInfo(undefined, positions[i - 1], positions[i]);
        horiDistance = horiDistance + info.length;
      }
    }
    this._entity.horiDistance = horiDistance;
  }
}