import NodeMap from "../../index";
import Polygon from "../../base/Polygon";
import MarkPolygon from "../../mark/MarkPolygon";

export class NoFlyPolygon extends MarkPolygon {
    static DRAWTYPE = "noFlyPolygon";
    constructor(options) {
        let defaultProps = Object.assign({
            category: NodeMap.Node.NOFLYZONE,
            color: options.color || "#0063FF"
        }, options);
        defaultProps.style = {
            position: defaultProps.positions[0],
            label: {
                text: `${defaultProps.name}`,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                backgroundPadding: new Cesium.Cartesian2(8, 4),
                font: "14px sans-serif",
                fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
                pixelOffset: new Cesium.Cartesian2(0, -32),
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 400
                )
            }
        };
        super(defaultProps);
        this._drawType = NoFlyPolygon.DRAWTYPE;
        this.initNoFlyPolygon(defaultProps);
    }
    initNoFlyPolygon() {
        this.entity.drawType = NoFlyPolygon.DRAWTYPE;
    }
}

export class NoFlyShowPolygon extends Polygon {
    static DRAWTYPE = "noFlyPolygon";
    constructor(options) {
        options.style = {
            id: options.id,
            position: options.positions[0],
            label: {
                text: `${options.name}`,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                backgroundPadding: new Cesium.Cartesian2(8, 4),
                font: "14px sans-serif",
                fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
                pixelOffset: new Cesium.Cartesian2(0, -32),
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 400
                )
            },
            polygon: {
                hierarchy: new Cesium.PolygonHierarchy(options.positions),
                material: Cesium.Color.fromCssColorString(options.overlayColor || '#D84A31')
                    .withAlpha(options.overlayAlpha || 0.45),
                fill: true,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
            },
            polyline: {
                positions: options.positions,
                width: options.outLineWidth || 1,
                clampToGround: true
            }
        };
        if (options.polylineDash) {
            options.style.polyline.material = new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.fromCssColorString(options.outLineColor || '#D84A31'),
                gapColor: Cesium.Color.TRANSPARENT,
                dashLength: 10,
                dashPattern: 255
            });
        } else {
            options.style.polyline.material = Cesium.Color.fromCssColorString(options.outLineColor || '#D84A31');
        }
        let defaultProps = Object.assign({
            category: NodeMap.Node.NOFLYZONE
        }, options);
        super(defaultProps);
        this._drawType = NoFlyShowPolygon.DRAWTYPE;
        this._noFlyType = defaultProps.noFlyType;
        this._canEdit = false;
    }
    get noFlyType() {
        return this._noFlyType;
    }
    get drawType() {
        return this._drawType;
    }
}