export function PolylineTrailMaterial(option = {}) {
  let img = getColorRibon();
  this._id = option.id ? option.id : "PolylineTrail";
  this._definitionChanged = new Cesium.Event();
  this._color = undefined;
  this._colorSubscription = undefined;
  this._time = new Date().getTime();
  this.color = option.color
    ? option.color
    : Cesium.Color.fromCssColorString("rgba(255,255,255, 1)");
  this.duration = option.duration ? option.duration : 2000;
  this.img = option.img ? option.img : img;
  this._map = option.map;
  this._resolu = resolutionFnc(option.map);
  this._length = 1;
}

Object.defineProperties(PolylineTrailMaterial.prototype, {
  isConstant: {
    get: () => {
      return false;
    },
    configurable: true,
  },
  definitionChanged: {
    get: function () {
      return this._definitionChanged;
    },
    configurable: true,
  },
  color: Cesium.createPropertyDescriptor("color"),
});

PolylineTrailMaterial.prototype.getType = function () {
  return this._id;
};

PolylineTrailMaterial.prototype.equals = function (other) {
  return this === other || other.img === this.img;
};

PolylineTrailMaterial.prototype.setLength = function (length) {
  this._length = length;
};

PolylineTrailMaterial.prototype.getValue = function (time, result) {
  if (!Cesium.defined(result)) {
    result = {};
  }
  result.color = Cesium.Property.getValueOrClonedDefault(
    this._color,
    time,
    Cesium.Color.WHITE,
    result.color
  );

  result.image = this.img;

  let cartographic;
  if (this._map.viewModel === 3) {
    const cameraPos = this._map.camera.position;
    const ellipsoid = this._map.scene.globe.ellipsoid;
    cartographic = ellipsoid.cartesianToCartographic(cameraPos);
  } else {
    cartographic = this._map.camera.positionCartographic;
  }
  const height = cartographic.height;

  // const last = this._resolu / height;
  // let re = last > 10 ? 10 : last;
  // re = re < 1 ? 1 : re;
  // result.resolution = re;

  const heightRe = 1 + Math.floor(height / 1000); // 1000米及以下纹理分辨率不变动
  const count = 1 + Math.floor(this._length / 200); // 基础长度与纹理的分配比 每一千米10个箭头
  const r = count / heightRe;
  result.resolution = r < 1 ? 1 : r;

  result.time =
    ((new Date().getTime() - this._time) % this.duration) / this.duration;
  return result;
};
function resolutionFnc(map3d) {
  let cartographic;
  if (map3d.viewModel === 3) {
    const cameraPos = map3d.camera.position;
    const ellipsoid = map3d.scene.globe.ellipsoid;
    cartographic = ellipsoid.cartesianToCartographic(cameraPos);
  } else {
    cartographic = map3d.camera.positionCartographic;
  }
  const height = cartographic.height;
  return height;
}

function getColorRibon() {
  let canvas = document.createElement("canvas");
  canvas.width = 300;
  canvas.height = 50;
  let ctx = canvas.getContext("2d");

  let g = Math.floor(Math.random() * 255);
  let b = Math.floor(Math.random() * 255);
  let r = Math.floor(Math.random() * 255);
  let grad = ctx.createLinearGradient(0, 0, 300, 50);
  grad.addColorStop(0, `rgba(${r}, ${g}, ${b}, 0.7)`);
  grad.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0.7)`);
  ctx.fillStyle = `rgba(${r}, ${g}, ${b}, 0.7)`;
  ctx.fillRect(0, 0, 300, 50);
  ctx.save();

  ctx.shadowBlur = 50; //设置像素模糊值为20；
  ctx.shadowOffsetX = 0; //横向值为15;
  ctx.shadowOffsetY = 0; //纵向值为15；
  ctx.shadowColor = `rgba(255,255, 255, 1)`;
  ctx.fillStyle = `rgba(255, 255, 255, 1)`;
  ctx.strokeStyle = "rgba(0,0,0,0)";
  ctx.fillRect(290, 0, 300, 50);
  ctx.stroke();
  ctx.fill();

  return canvas.toDataURL("image/png");
}

function addMaterial(type, source) {
  Cesium.Material._materialCache.addMaterial(type, {
    fabric: {
      type: type,
      uniforms: {
        color: new Cesium.Color(1, 1, 1, 1),
        image: "czm_defaultImage",
        time: 100,
        resolution: 1,
      },
      source: source,
    },

    translucent: (material) => {
      return true;
    },
  });
}

export function checkClassAdd(typeId) {
  let type = typeId ? typeId : "PolylineTrail";
  if (!Cesium.Material._materialCache._materials[type]) {
    // "vec2 resolution = czm_viewport.zw;" +
    // "vec2 uv = gl_FragCoord.xy / resolution.xy;" +
    let source =
      "czm_material czm_getMaterial(czm_materialInput materialInput)" +
      "{" +
      "czm_material material = czm_getDefaultMaterial(materialInput);" +
      "vec2 st = materialInput.st;" +
      "vec4 colorImage = texture2D(image, vec2(fract(st.s * 10.0 * resolution - time), fract(st.t)));" +
      "material.alpha = colorImage.a;" +
      "material.diffuse = colorImage.rgb;" +
      "return material;" +
      "}";
    addMaterial(type, source);
  }
}

export function newColorRibon(map) {
  let id = "PolylineTrail";
  checkClassAdd(id);
  let color = "/img/map/road.png"; // getColorRibon(); //
  return new PolylineTrailMaterial({ img: color, map });
}

export function randomColor(a) {
  let alpha = a ? a : 1;
  let g = Math.floor(Math.random() * 255);
  let b = Math.floor(Math.random() * 255);
  let r = Math.floor(Math.random() * 255);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
