import Point from './../../base/Point';
import Line from "../../base/Line";
import Polygon from "../../base/Polygon";

export class FlyPoint extends Point {
    static DRAWTYPE = "FlyPoint";
    constructor(options) {
        options.style = {
            id: options.id,
            position: options.position,
            billboard: {
                image: options.icon,
                width: options.iconSize || 60,
                height: options.iconSize || 60,
                rotation: -Cesium.Math.toRadians(Number(options.yaw) || 0)
            }
        };
        // options.style = {
        //     id: options.id,
        //     position: options.position,
        //     model: {
        //         uri: options.icon,// 模型路径
        //         scale: 0.5,
        //         minimumPixelSize: 90,
        //         runAnimations: true  //是否运行模型中的动画效果
        //     },
        //     orientation: Cesium.Transforms.headingPitchRollQuaternion(options.position, 
        //         new Cesium.HeadingPitchRoll(
        //             Cesium.Math.toRadians((Number(options.yaw) || 0) - 90), // 航向角
        //             // Cesium.Math.toRadians(Number(options.pitch) || 0), // 俯仰角
        //             // Cesium.Math.toRadians(Number(options.roll) || 0) // 横滚角
        //             0, // 俯仰角
        //             0 // 横滚角
        //         )
        //     )
        // };
        if (options.showLabel) {
            options.style.label = {
                text: options.labelText || "",
                font: "12px",
                showBackground: true,
                backgroundColor: new Cesium.Color.fromCssColorString("rgba(0, 0, 0, 0.8)"),
                backgroundPadding: new Cesium.Cartesian2(10, 8),
                pixelOffset: new Cesium.Cartesian2(0, 20),
                verticalOrigin: Cesium.VerticalOrigin.TOP
            }
        }
        super(options);
        this._drawType = FlyPoint.DRAWTYPE;
        this._canEdit = false;
    }
}

export class FlyLine extends Line {
    constructor(options) {
        options.style = {
            id: options.id,
            polyline: {
                positions: [options.position],
                material: Cesium.Color.fromCssColorString(options.lineColor || "#FFB100"),
                width: options.lineWidth || 2,
                depthFailMaterial: Cesium.Color.fromCssColorString(options.lineColor || "#FFB100"),
                // clampToGround: true
            }
        };
        super(options);
        this._canEdit = false;
    }
}

export class FlyVisualPolygon  extends Polygon {
    constructor(options) {
        options.style = {
            id: options.id,
            polygon: {
                hierarchy: new Cesium.PolygonHierarchy(options.polygonPositions),
                material: Cesium.Color.fromCssColorString(options.polygonColor || '#FFB100')
                    .withAlpha(options.polygonAlpha || 0.45),
                fill: true,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
            }
        };
        super(options);
        this._canEdit = false;
    }
}

export class FlyHomePoint extends Point {
    constructor(options) {
        options.style = {
            id: options.id,
            position: options.homePosition,
            billboard: {
                image: options.homeIcon,
                width: options.homeIconSize || 60,
                height: options.homeIconSize || 60
            }
        };
        super(options);
        this._canEdit = false;
    }
}