import Node from "./base/Node";
import Line from "./base/Line";
import Point from "./base/Point";
import OilWellPoint from "./bussiness/Oil/OilWellPoint";
import OilPipeline from "./bussiness/Oil/OilPipeline";
import NavigationLine from "./bussiness/NavigationLine/NavigationLine";
import WarningPoint from "./bussiness/Warning/WarningPoint";
import OilScreenWarningPoint from "./bussiness/oilScreenWarning/oilScreenWarning";
import StorageTank from "./bussiness/Oil/StorageTank";
import MeasurementStation from "./bussiness/Oil/MeasurementStation";
import StationStorage from "./bussiness/Oil/StationStorage";
import OilRoad from "./bussiness/Oil/OilRoad";
import PowerEquipment from "./bussiness/Oil/PowerEquipment";
import PowerLine from "./bussiness/Oil/PowerLine";
import GasWell from "./bussiness/Oil/GasWell";
import MarkPoint from "./mark/MarkPoint";
import MarkCircle from "./mark/MarkCircle";
import TemporaryMarkCircle from "./mark/TemporaryMarkCircle";
import MarkLine from "./mark/MarkLine";
import TemporaryMarkLine from "./mark/TemporaryMarkLine";
import MarkPolygon from "./mark/MarkPolygon";
import TemporaryMarkPolygon from "./mark/TemporaryMarkPolygon";
import Uav from "./bussiness/LiveFlyRoute/Uav";
import { FlyPoint, FlyLine, FlyVisualPolygon, FlyHomePoint } from "./bussiness/LiveFlyRoute/FlyRoute";
import HomePoint from "./bussiness/Project/HomePoint";
import ProjectCenterPoint from "./bussiness/Project/ProjectCenterPoint";
import {NoFlyCircle, NoFlyShowCircle} from "./bussiness/noFlyZone/noFlyCircle";
import {NoFlyPolygon, NoFlyShowPolygon} from "./bussiness/noFlyZone/noFlyPolygon";
import {AirportPoint, AirportShowPoint} from "./bussiness/airport/airportPoint";
import {AirportPolygon, AirportShowPolygon} from "./bussiness/airport/airportPolygon";
import RouteLine from "./bussiness/RouteLine/RouteLine";
import UAVRoute from "./bussiness/LiveFlyRoute/UAVRoute";
import BaseStationPoint from "./bussiness/baseStation/baseStationPoint";
import {HangarPoint, HangarShowPoint} from "./bussiness/hangar/hangarPoint";
import Route from "./bussiness/Route/Route";
import OperationArea from "./bussiness/OperationArea/OperationArea";

export default {
    Node,
    Line,
    Point,
    OilWellPoint,
    OilPipeline,
    NavigationLine,
    WarningPoint,
    OilScreenWarningPoint,
    StorageTank,
    MeasurementStation,
    StationStorage,
    OilRoad,
    PowerEquipment,
    PowerLine,
    GasWell,
    Uav,
    FlyPoint,
    FlyLine,
    FlyVisualPolygon,
    FlyHomePoint,
    HomePoint,
    ProjectCenterPoint,
    MarkPoint,
    MarkCircle,
    TemporaryMarkCircle,
    MarkLine,
    TemporaryMarkLine,
    MarkPolygon,
    TemporaryMarkPolygon,
    NoFlyCircle,
    NoFlyShowCircle,
    NoFlyPolygon,
    NoFlyShowPolygon,
    AirportPoint,
    AirportShowPoint,
    AirportPolygon,
    AirportShowPolygon,
    RouteLine,
    UAVRoute,
    BaseStationPoint,
    HangarPoint,
    HangarShowPoint,
    Route,
    OperationArea
}