import MarkPoint from "../../mark/MarkPoint";
import NodeMap from "../../index"
import oilPowerEquipment from "@/assets/img/map/oilPowerEquipment.png";
import oilPowerEquipmentActive from "@/assets/img/map/oilPowerEquipment-active.png";
import { getPowerEquipmentStyle } from "../../../style/index";

export default class PowerEquipment extends MarkPoint {
  static DRAWTYPE = "oilPowerEquipment";
  constructor(options) {
    let defaultProps = Object.assign({
      category: NodeMap.Node.INSPECTION,
      icon: oilPowerEquipment,
      iconHover: oilPowerEquipmentActive,
      iconSelecte: oilPowerEquipmentActive,
    }, options)
    defaultProps.style = getPowerEquipmentStyle(defaultProps);
    super(defaultProps);
    this._drawType = PowerEquipment.DRAWTYPE
    this._hover = null;
    this.initPowerEquipment(defaultProps);
  }

  initPowerEquipment() {
    this.entity.drawType = PowerEquipment.DRAWTYPE;
  }
}
