import NodeMap from "../../index"
import MarkPoint from "../../mark/MarkPoint";
import oilMeasurementStation from "@/assets/img/map/oilMeasurementStation.png";
import oilMeasurementStationIcon from "@/assets/img/map/oilMeasurementStation-active.png";
import { getMeasurementStationStyle } from "../../../style/index";
export default class MeasurementStation extends MarkPoint {
  static DRAWTYPE = "oilMeasurementStation";
  constructor(options) {
    let defaultProps = Object.assign({
      category: NodeMap.Node.INSPECTION,
      icon: oilMeasurementStation,
      iconHover: oilMeasurementStationIcon,
      iconSelecte: oilMeasurementStationIcon,
    }, options)
    defaultProps.style = getMeasurementStationStyle(defaultProps);
    super(defaultProps);
    this._drawType = MeasurementStation.DRAWTYPE
    this._hover = null;
    this.initMeasurementStation(defaultProps);
  }

  initMeasurementStation() {
    this.entity.drawType = MeasurementStation.DRAWTYPE;
  }
}
