import Point from "../base/Point";
export default class MarkInflectionPoint extends Point {
  // 小圆点半径
  static R = 6;
  static selected_R = 10
  constructor(options) {
    options.style = {
      billboard: {
        image: '',
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND
      },
    };
    super(options);
    this._R = options.R? options.R: MarkInflectionPoint.R
    this._color = options.color
    this._heighLight = false
    this._selected = options.selected? true: false
    this._index = options.index;
    this._normalImg = options.inflectionImage? options.inflectionImage:this.getNormalInflectionPointStyle()
    this._selectedImg = this.getSelectedInflectionPointStyle()
    this.initMarkInflectionPoint()
  }

  get R () {
    return this._R
  }

  get color () {
    return this._color
  }

  set color(value) {
    this._color = value
    this.selectedImg = this.getSelectedInflectionPointStyle()
  }

  get index() {
    return this._index;
  }

  get selected() {
    return this._selected
  }

  get selectedImg() {
    return this._selectedImg
  }

  set selectedImg(value) {
    this.entity.billboard.image = value
    this._selectedImg = value
  }

  get normalImg() {
    return this._normalImg
  }

  set normalImg(value) {
    this._normalImg = value
  }

  set selected(value) {
    if(value){
      this.entity.billboard.image = this.selectedImg
    } else {
      this.entity.billboard.image = this.normalImg
    }
    this._selected = value
  }

  initMarkInflectionPoint() {
    this.entity.billboard.image = this.selected? this.selectedImg : this.normalImg
  }

  onSelect() {
    this.selected = true
  }

  unSelect() {
    this.selected = false
  }

  getNormalInflectionPointStyle() {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 24;
    canvas.height = 24;
    ctx.beginPath();
    ctx.arc(12, 12, this.R, 0, Math.PI * 2, true); 
    ctx.closePath();
    ctx.fillStyle = "white";
    ctx.fill(); 
    ctx.font = "16px lion";
    ctx.textAlign = "center";
    ctx.fillStyle = "#333333";
    let image = new Image();
    image.src = canvas.toDataURL("image/png");
    return image
  }

  getSelectedInflectionPointStyle() {
    let r = MarkInflectionPoint.selected_R
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 24;
    canvas.height = 24;
    // 按照下边参数开始绘制新路径
    ctx.beginPath(); 
    //（圆心X坐标，圆心Y坐标，半径，开始角度（弧度），结束角度弧度，是否按照顺时针画）
    ctx.arc(12, 12, r, 0, Math.PI * 2, true); 
     //关闭路径
    ctx.closePath();
    // 设置填充颜色
    ctx.fillStyle = "white"; 
    ctx.fill(); 
    ctx.font = "16px lion";
    ctx.textAlign = "center";
    ctx.fillStyle = this.color;
    ctx.fillText(`${this.index}`, 12, 18);
    let image = new Image();
    image.src = canvas.toDataURL("image/png");
    return image
  }

  hover() {}

  leave() {}

  toHeighLight() {}

  cancelHeighLight() {}
}