import Point from "../../base/Point";
import {
  getNavigationLineStartStyle,
  getNavigationLineEndStyle,
} from "../../../style/index";
class NavigationLineStartNode extends Point {
  constructor(options) {
    options.style = getNavigationLineStartStyle(options);
    super(options);
  }
}

class NavigationLineEndNode extends Point {
  constructor(options) {
    options.style = getNavigationLineEndStyle(options);
    super(options);
  }
}

export { NavigationLineStartNode, NavigationLineEndNode };
