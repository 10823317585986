import Polygon from "../../base/Polygon";
import MarkPolygon from "../../mark/MarkPolygon";
import NodeMap from "../../index";

export class AirportPolygon extends MarkPolygon {
    static DRAWTYPE = "airportPolygon";
    constructor(options) {
        let defaultProps = Object.assign({
            category: NodeMap.Node.AIRPORT,
            color: "#0063FF"
        }, options);
        defaultProps.style = {
            position: defaultProps.positions[0],
            label: {
                text: `${defaultProps.name}`,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                backgroundPadding: new Cesium.Cartesian2(8, 4),
                font: "14px sans-serif",
                fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
                pixelOffset: new Cesium.Cartesian2(0, -32),
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 400
                )
            }
        };
        super(defaultProps);
        this._drawType = AirportPolygon.DRAWTYPE;
        this.initAirportPolygon(defaultProps);
    }
    initAirportPolygon() {
        this.entity.drawType = AirportPolygon.DRAWTYPE;
    }
}

export class AirportShowPolygon extends Polygon {
    constructor(options) {
        options.style = {
            id: options.id,
            position: options.positions[0],
            label: {
                text: `${options.name}`,
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString("#000000AA"),
                backgroundPadding: new Cesium.Cartesian2(8, 4),
                font: "14px sans-serif",
                fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
                pixelOffset: new Cesium.Cartesian2(0, -32),
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000 * 400
                )
            },
            polygon: {
                hierarchy: new Cesium.PolygonHierarchy(options.positions),
                material: Cesium.Color.fromCssColorString(options.overlayColor || '#0063FF')
                    .withAlpha(0.2),
                fill: true,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
            },
            polyline: {
                positions: options.positions,
                width: 4,
                material: Cesium.Color.fromCssColorString('#0063FF'),
                clampToGround: true
            }
        };
        super(options);
        this._canEdit = false;
        this._selected = false;
    }
}