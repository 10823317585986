import NodeMap from "../index";
import Point from "../base/Point";
import { tangentialCircle } from "@/util/map";
import { getMarkCircleStyle } from "../../style/index";

export default class MarkCircle extends Point {
    static DRAWTYPE = "MarkCircle";
    static ALPHA = "33";
    constructor(options) {
        let color = options.color ? options.color : "#0096FF";
        let defaultProps = Object.assign({
            category: NodeMap.Node.MARK,
            textColor: "#FFFFFF"
        }, options);
        defaultProps.color = color;
        defaultProps.style = defaultProps.style ? defaultProps.style : getMarkCircleStyle(defaultProps);
        super(defaultProps);
        this._color = defaultProps.color;
        this._drawType = MarkCircle.DRAWTYPE;
        this._createBy = defaultProps.createBy ? defaultProps.createBy : "";
        this._selected = false;
        this._canEdit = typeof defaultProps.canEdit === 'boolean' ? defaultProps.canEdit : true;
        this.initMarkCircle(defaultProps);
    }
    get name() {
        return this._name;
    }
    set name(value) {
        this.entity.label.text._value = value;
        this._name = value;
    }
    get createBy() {
        return this._createBy;
    }
    set createBy(val) {
        this._createBy = val;
    }
    get drawType() {
        return this._drawType;
    }
    get entity() {
        return this._entity;
    }
    get canEdit() {
        return this._canEdit;
    }
    get color() {
        return this._color;
    }
    set color(value) {
        this.entity.polyline.material.color = new Cesium.Color.fromCssColorString(value);
        this.entity.label.backgroundColor = new Cesium.Color.fromCssColorString(value);
        this.entity.polygon.material = new Cesium.Color.fromCssColorString(value + MarkCircle.ALPHA);
        this._color = value;
    }
    get selected() {
        return this._selected;
    }
    set selected(value) {
        if (this._selected !== value) {
            this.entity.polyline.material.outlineWidth = value ? 3 : 0;
            this.entity.polyline.width = value ? 8 : 5;
            this._selected = value;
        }
    }
    set hovering(value) {
        if (this._hovering !== value && !this.selected) {
            this.entity.polyline.material.outlineWidth = !value && !this.selected ? 0 : 3;
            this.entity.polyline.width = !value && !this.selected ? 5 : 8;
            this._hovering = value;
        }
    }
    move(args) {
        this.entity.position = new Cesium.CallbackProperty(function() {
            return args.position;
        }, false);
        this._position = args.position;
        const cartographic = Cesium.Cartographic.fromCartesian(args.position);
        const longitude = Cesium.Math.toDegrees(cartographic.longitude);
        const latitude = Cesium.Math.toDegrees(cartographic.latitude);
        let linePoints = tangentialCircle(
            [longitude, latitude],
            this.entity.ellipse.semiMajorAxis.getValue()
        );
        this.entity.polyline.positions = new Cesium.CallbackProperty(function() {
            return linePoints;
        }, false);
        this.entity.polygon.hierarchy = new Cesium.CallbackProperty(function() {
            return new Cesium.PolygonHierarchy(linePoints);
        }, false);
    }
    moveRadius(args) {
        let radius = args.radius > 5 ? args.radius : 5; // 控制最小半径为5
        this.entity.ellipse.semiMinorAxis = new Cesium.CallbackProperty(function() {
            return radius * 1;
        }, false);
        this.entity.ellipse.semiMajorAxis = new Cesium.CallbackProperty(function() {
            return radius * 1;
        }, false);
        const cartographic = Cesium.Cartographic.fromCartesian(args.position);
        const longitude = Cesium.Math.toDegrees(cartographic.longitude);
        const latitude = Cesium.Math.toDegrees(cartographic.latitude);
        let linePoints = tangentialCircle([longitude, latitude], radius);
        this.entity.polyline.positions = new Cesium.CallbackProperty(function() {
            return linePoints;
        }, false);
        this.entity.polygon.hierarchy = new Cesium.CallbackProperty(function() {
            return new Cesium.PolygonHierarchy(linePoints);
        }, false);
    }
    initMarkCircle(defaultProps) {
        const cartographic = Cesium.Cartographic.fromCartesian(defaultProps.position);
        const longitude = Cesium.Math.toDegrees(cartographic.longitude);
        const latitude = Cesium.Math.toDegrees(cartographic.latitude);
        let linePoints = tangentialCircle([longitude, latitude], defaultProps.radius);
        this.entity.ellipse = {
            semiMinorAxis: defaultProps.radius * 1,
            semiMajorAxis: defaultProps.radius * 1,
            material: Cesium.Color.fromCssColorString('rgba(0, 0, 0, 0)'),
            outline: false,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        }
        this.entity.polyline = new Cesium.PolylineGraphics({
            width: 5,
            positions: linePoints,
            material: new Cesium.PolylineOutlineMaterialProperty({
                color: Cesium.Color.fromCssColorString(defaultProps.color),
                outlineColor: Cesium.Color.fromCssColorString("#FFFFFF"),
                outlineWidth: 0
            }),
            clampToGround: true,
        });
        this.entity.polygon = new Cesium.PolygonGraphics({
            hierarchy: new Cesium.PolygonHierarchy(linePoints),
            material: Cesium.Color.fromCssColorString(defaultProps.color + MarkCircle.ALPHA),
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        });
        this._entity.canEdit = true;
        this._entity.drawType = MarkCircle.DRAWTYPE;
    }
    hover() {
        this.hovering = true;
    }
    leave() {
        this.hovering = false;
    }
}