/*
 * @Description:
 * @Autor: wq
 * @Date: 2021-06-03 09:38:43
 * @watermark: 成都傲势科技
 * @LastEditors: wq
 */

export function screen2cartesian3(viewer, position) {
  const ray = viewer.camera.getPickRay(position);
  const pos = viewer.scene.globe.pick(ray, viewer.scene);
  return pos;
}

export function screen2world(viewer) {
  const ray = viewer.camera.getPickRay(event.position);
  const position = viewer.scene.globe.pick(ray, viewer.scene);
  const coordsWithHeight = decar2wgs(viewer, position);
  return coordsWithHeight;
}

/***
 * 世界坐标转wgs84
 * **/
export function decar2wgs(viewer, cartesian3) {
  const ellipsoid = viewer.scene.globe.ellipsoid;
  const cartographic = ellipsoid.cartesianToCartographic(cartesian3);
  const lat = Cesium.Math.toDegrees(cartographic.latitude);
  const lng = Cesium.Math.toDegrees(cartographic.longitude);
  const alt = cartographic.height;
  return [lng, lat, alt];
}

export function cartesian2screen(viewer, cartesian) {
  return viewer.scene.cartesianToCanvasCoordinates(
    cartesian,
    new Cesium.Cartesian2()
  );
}

export function get2PointSpaceDistance(start, end) {
  const distance = 0;
  const point1cartographic = Cesium.Cartographic.fromCartesian(start);
  const point2cartographic = Cesium.Cartographic.fromCartesian(end);
  //
  const geodesic = new Cesium.EllipsoidGeodesic();
  geodesic.setEndPoints(point1cartographic, point2cartographic);
  let s = geodesic.surfaceDistance;
  //return distance
  s = Math.sqrt(
    Math.pow(s, 2) +
      Math.pow(point2cartographic.height - point1cartographic.height, 2)
  );
  distance = distance + s;
  return distance.toFixed(2);
}

export function getDistance(startPosition, endPosition) {
  const dis = Cesium.Cartesian3.distance(startPosition, endPosition);
  return dis;
}

/***
 * cartesia to wgs84
 * **/
export function cartesian2wgs(viewer, cartesian3, withOutZ = false) {
  const ellipsoid = viewer.scene.globe.ellipsoid;
  const cartographic = ellipsoid.cartesianToCartographic(cartesian3);
  const lat = Cesium.Math.toDegrees(cartographic.latitude);
  const lng = Cesium.Math.toDegrees(cartographic.longitude);
  const result = [lng, lat];
  if(!withOutZ){
    let height = cartographic.height
    // let height = viewer.scene.globe.getHeight(cartographic)
    height = height > 0? height: 0 
    result.push(height);
  }
  return result;
}

export function setCartesian3Height(viewer, cartesian3, height, replace) {
  const ellipsoid = viewer.scene.globe.ellipsoid;
  const cartographic = ellipsoid.cartesianToCartographic(cartesian3);
  const lat = Cesium.Math.toDegrees(cartographic.latitude);
  const lng = Cesium.Math.toDegrees(cartographic.longitude);
  const cartesian = Cesium.Cartesian3.fromDegrees(lng, lat, replace? height: cartographic.height + height);
  return cartesian;
}

export function cartesianArr2wgsArr(viewer, cartesianArr, withOutZ) {
  const result = [];
  for (let i = 0; i < cartesianArr.length; i++) {
    result.push(cartesian2wgs(viewer, cartesianArr[i], withOutZ));
  }
  return result;
}

export function wgsArr2cartesianArr(coords) {
  const result = [];
  for (let i = 0; i < coords.length; i++) {
    let cartesian = Cesium.Cartesian3.fromDegrees(
      coords[i][0] * 1,
      coords[i][1] * 1,
      coords[i][2] * 1 ? coords[i][2] * 1 : 0
    );
    result.push(cartesian);
  }
  return result;
}

// 贝塞尔曲线二维转三维  返回一个三维点数组
// 参数： x1,y1,x2,y2,h 两点经纬度坐标和飞线高度
export function getBSRPoints(x1, y1, x2, y2, h) {
  const point1 = [y1, 0];
  const point2 = [(y2 + y1) / 2, h];
  const point3 = [y2, 0];
  const arr = getBSR(point1, point2, point3);
  const arr3d = [];
  for (let i in arr) {
    let s = y2 - y1;
    if (s == 0) {
      s = 1;
    }
    let x = ((x2 - x1) * (arr[i][0] - y1)) / s + x1;
    arr3d.push([x, arr[i][0], arr[i][1]]);
  }
  return arr3d;
}
// 生成贝塞尔曲线
function getBSR(point1, point2, point3) {
  const ps = [
    { x: point1[0], y: point1[1] },
    { x: point2[0], y: point2[1] },
    { x: point3[0], y: point3[1] },
  ];
  const guijipoints = CreateBezierPoints(ps, 100);
  return guijipoints;
}
// 贝赛尔曲线算法
// 参数：
// anchorpoints: [{ x: 116.30, y: 39.60 }, { x: 37.50, y: 40.25 }, { x: 39.51, y: 36.25 }]
function CreateBezierPoints(anchorpoints, pointsAmount) {
  const points = [];
  for (let i = 0; i < pointsAmount; i++) {
    const point = MultiPointBezier(anchorpoints, i / pointsAmount);
    points.push([point.x, point.y]);
  }
  return points;
}
function MultiPointBezier(points, t) {
  const len = points.length;
  let x = 0,
    y = 0;
  let erxiangshi = function (start, end) {
    let cs = 1,
      bcs = 1;
    while (end > 0) {
      cs *= start;
      bcs *= end;
      start--;
      end--;
    }
    return cs / bcs;
  };
  for (let i = 0; i < len; i++) {
    let point = points[i];
    x +=
      point.x *
      Math.pow(1 - t, len - 1 - i) *
      Math.pow(t, i) *
      erxiangshi(len - 1, i);
    y +=
      point.y *
      Math.pow(1 - t, len - 1 - i) *
      Math.pow(t, i) *
      erxiangshi(len - 1, i);
  }
  return { x: x, y: y };
}

//空间两点距离计算函数
export function getSpaceDistance(positions) {
  let distance = 0;
  for (let i = 0; i < positions.length - 1; i++) {
    if (
      "undefined" == typeof positions[i] ||
      "undefined" == typeof positions[i + 1]
    ) {
      面积;
      break;
    }
    distance =
      distance + Cesium.Cartesian3.distance(positions[i], positions[i + 1]);
  }
  distance = distance.toFixed(2);
  distance = Number(distance);
  distance =
    distance > 1000 ? (distance / 1000).toFixed(2) + "km" : distance + "m";
  return distance;
}

//计算多边形面积
export function getSpaceArea(points) {
  const degreesPerRadian = 180.0 / Math.PI; //弧度转化为角度

  /*角度*/
  function Angle(p1, p2, p3) {
    let bearing21 = Bearing(p2, p1);
    let bearing23 = Bearing(p2, p3);
    let angle = bearing21 - bearing23;
    if (angle < 0) {
      angle += 360;
    }
    return angle;
  }

  /*方向*/
  function Bearing(from, to) {
    const radiansPerDegree = Math.PI / 180.0; //角度转化为弧度(rad)
    from = Cesium.Cartographic.fromCartesian(from);
    to = Cesium.Cartographic.fromCartesian(to);

    let lat1 = from.latitude * radiansPerDegree;
    let lon1 = from.longitude * radiansPerDegree;
    let lat2 = to.latitude * radiansPerDegree;
    let lon2 = to.longitude * radiansPerDegree;
    let angle = -Math.atan2(
      Math.sin(lon1 - lon2) * Math.cos(lat2),
      Math.cos(lat1) * Math.sin(lat2) -
        Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)
    );
    if (angle < 0) {
      angle += Math.PI * 2.0;
    }
    angle = angle * degreesPerRadian; //角度
    return angle;
  }

  function distance(point1, point2) {
    let point1cartographic = Cesium.Cartographic.fromCartesian(point1);
    let point2cartographic = Cesium.Cartographic.fromCartesian(point2);
    /**根据经纬度高度计算出距离**/
    let geodesic = new Cesium.EllipsoidGeodesic();
    geodesic.setEndPoints(point1cartographic, point2cartographic);
    let s = geodesic.surfaceDistance;
    //console.log(Math.sqrt(Math.pow(distance, 2) + Math.pow(endheight, 2)));
    //返回两点之间的距离
    s = Math.sqrt(
      Math.pow(s, 2) +
        Math.pow(point2cartographic.height - point1cartographic.height, 2)
    );
    return s;
  }

  let res = 0;
  //拆分三角曲面

  for (let i = 0; i < points.length - 2; i++) {
    let j = (i + 1) % points.length;
    let k = (i + 2) % points.length;
    let totalAngle = Angle(points[i], points[j], points[k]);

    let dis_temp1 = distance(points[j], points[i]);
    let dis_temp2 = distance(points[j], points[k]);
    res += (dis_temp1 * dis_temp2 * Math.sin(totalAngle)) / 2;
    // console.log(res);
  }

  if (res < 1000000) {
    res = Math.abs(res).toFixed(4) + "m²";
  } else {
    res = Math.abs((res / 1000000.0).toFixed(4)) + "km²";
  }

  return res;
}

/**
 * 计算一组坐标组成多边形的面积
 * @param {*} positions
 */
export function getPositionsArea(positions) {
  let result = 0;
  if (positions) {
    let h = 0;
    const temp = positions.slice();
    temp.push(temp[0]);
    for (let i = 1; i < temp.length; i++) {
      let oel = temp[i - 1];
      let el = temp[i];
      h += oel.x * el.y - el.x * oel.y;
    }
    result = Math.abs(h);
  }
  return result;
}

//度转度°分′秒″
export function degressFormat(val) {
  if (typeof val == "undefined" || val == "") {
    return "";
  }
  val = val.toString();
  var i = val.indexOf(".");
  var strDu = i < 0 ? val : val.substring(0, i); //获取度
  var strFen = 0;
  var strMiao = 0;
  if (i > 0) {
    var strFen = "0" + val.substring(i);
    strFen = strFen * 60 + "";
    i = strFen.indexOf(".");
    if (i > 0) {
      strMiao = "0" + strFen.substring(i);
      strFen = strFen.substring(0, i); //获取分
      strMiao = strMiao * 60 + "";
      i = strMiao.indexOf(".");
      strMiao = strMiao.substring(0, i + 4); //取到小数点后面三位
      strMiao = parseFloat(strMiao).toFixed(2); //精确小数点后面两位
    }
  }
  return `${strDu}°${strFen}′${strMiao}″`;
}

/**
 * 获取84坐标的距离 球面 带高度距离
 * @param {*} positions
 */
export function getPositionDistance(positions) {
  let distance = 0;
  for (let i = 0; i < positions.length - 1; i++) {
    const s = getLength(positions[i], positions[i + 1]);
    distance = distance + s;
  }
  return distance;
}

export function drawCanvas(number, color, image) {
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  canvas.width = 64;
  canvas.height = 64;
  ctx.drawImage(image, 0, 0, 64, 64);
  ctx.font = "bold 25px Arial";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillStyle = "#fff";
  ctx.fillText(number, 32, 32);
  return canvas.toDataURL("image/png");
}

// 计算两点距离
export function getLength(start, end) {
  const startCartographic = Cesium.Cartographic.fromCartesian(start);
  const endCartographic = Cesium.Cartographic.fromCartesian(end);
  const geodesic = new Cesium.EllipsoidGeodesic(
    startCartographic,
    endCartographic
  );
  let s = geodesic.surfaceDistance;
  s = Math.sqrt(
    Math.pow(s, 2) +
      Math.pow(endCartographic.height - startCartographic.height, 2)
  );
  return s;
}

export function getMidpoint(start, end) {
  // Get the end position from the polyLine's callback.
  const startCartographic = Cesium.Cartographic.fromCartesian(start);
  const endCartographic = Cesium.Cartographic.fromCartesian(end);
  const geodesic = new Cesium.EllipsoidGeodesic(
    startCartographic,
    endCartographic
  );
  const midpointCartographic = geodesic.interpolateUsingFraction(0.5);
  return Cesium.Cartesian3.fromRadians(
    midpointCartographic.longitude,
    midpointCartographic.latitude
  );
}


// 获取两点之前距离
export function getSegmentInfo(map, start, end, widthHeight = false) {
  const startCartographic = Cesium.Cartographic.fromCartesian(start);
  const endCartographic = Cesium.Cartographic.fromCartesian(end);
  const geodesic = new Cesium.EllipsoidGeodesic(
    startCartographic,
    endCartographic
  );
  let height1 = startCartographic.height;
  let height2 = endCartographic.height;
  if (widthHeight) {
    height1 = map.scene.globe.getHeight(startCartographic);
    height2 = map.scene.globe.getHeight(endCartographic);
  }
  // const height1 = map.scene.sampleHeight(startCartographic);
  // const height2 = map.scene.sampleHeight(endCartographic);

  // get length
  let length = geodesic.surfaceDistance;
  length = Math.sqrt(
    Math.pow(length, 2) +
      Math.pow(height2 - height1, 2)
  );

  const mindPoint =  {
    x: (start.x + end.x) / 2,
    y: (start.y + end.y) / 2,
    z: (start.z + end.z) / 2,
  };
  return {
    length,
    mindPoint,
    height: height2 - height1,
  };
}

export function geometry2cartesain(geometry) {
  let max = [0, 0];
  let min = [1000, 1000];
  if (geometry.type === "Polygon") {
    let coord = [];
    geometry.coordinates.map((hole) => {
      hole.map((point) => {
        const lng = point[0];
        const lat = point[1];
        if (max[0] < lng) {
          max[0] = lng;
        }
        if (max[1] < lat) {
          max[1] = lat;
        }
        if (min[0] > lng) {
          min[0] = lng;
        }
        if (min[1] > lat) {
          min[1] = lat;
        }
        coord.push(Cesium.Cartesian3.fromDegrees(lng, lat));
      });
    });
    return {
      bound: [...min, ...max],
      coord,
    };
  }
  return null;
}

export function getCanvasLabel(label, fontSize = 14, left = 0, top = 10) {
  const c = document.createElement("canvas");
  c.width = label.length * fontSize * 0.8 + left;
  c.height = fontSize + top * 2;
  const ctx = c.getContext("2d");
  ctx.font = `bold ${fontSize}px Arial`;
  ctx.fillStyle = "rgba(0,0,0, 0.8)";
  ctx.fillRect(0, 0, c.width, c.height);
  ctx.fillStyle = "#ffffff";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillText(label, c.width / 2, c.height / 2);
  return c.toDataURL("image/png");
}

export function labelEntity2geojson(viewer, entity, anchor) {
  let geojsonMap = {
    'Point': 'point',
    'LineString': 'polyline',
    'Polygon': 'polygon'
  }
  const graphic = entity[geojsonMap[anchor]];
  let coordinates = [];
  let typeName = "";
  let color = "";
  let horiDistance = 0;
  let horiArea = 0;
  let horiRound = 0;
  let name = entity.label.text.getValue()
  if (anchor === "Point") {
    typeName = "Point";
    let old;
    if (!entity.position.getValue) {
      old = entity.position;
    } else {
      old = entity.position.getValue(Date.now());
    }
    coordinates = cartesian2wgs(viewer, old, false);
  } else if (anchor === "LineString") {
    typeName = "LineString";
    let old;
    if (!graphic.positions.getValue) {
      old = graphic.positions;
    } else {
      old = graphic.positions.getValue(Date.now()).slice();
    }

    coordinates = cartesianArr2wgsArr(viewer, old, false);
    color = entity.polyline.material.getValue().color.toCssHexString();
    horiDistance = entity.horiDistance;
  } else if (anchor === "Polygon") {
    typeName = "Polygon";
    let old;
    if (!graphic.hierarchy.getValue) {
      old = graphic.hierarchy.positions.slice();
    } else {
      old = graphic.hierarchy.getValue(Date.now()).positions.slice();
    }
    const line = cartesianArr2wgsArr(viewer, old, false);
    line.push(line[0]);
    horiArea = entity.horiArea;
    horiRound = entity.horiRound;
    coordinates = [line];
    color = entity.polyline.material.getValue().color.toCssHexString();
  }

  let result = {
    type: "Feature",
    geometry: {
      type: typeName,
      coordinates: coordinates,
    },
    properties: {
      color,
      // name,
      // id: entity.id,
      // horiDistance,
      // horiArea,
      // horiRound,
    },
  };
  if (entity.label) {
    result.properties.text = entity.orgText;
  }
  return result;
}

export function labelNode2geojson(viewer, node) {
  let coordinates = [];
  let color = node.color;
  let icon = node.isFire? '1': ''
  const type = node.type
  if (type === "Point") {
    coordinates = cartesian2wgs(viewer, node.position, false);
  } else if (type === "LineString") {
    coordinates = cartesianArr2wgsArr(viewer, node.positions, false);
  } else if (type === "Polygon") {
    const line = cartesianArr2wgsArr(viewer, node.positions, false);
    line.push(line[0]);
    coordinates = [line];
  }

  let result = {
    type: "Feature",
    geometry: {
      type,
      coordinates: coordinates,
    },
    properties: {
      color,
      icon,
    },
  };
  return result;
}

export function getCenter(positions) {
  let polyCenter = Cesium.BoundingSphere.fromPoints(positions).center;
  return polyCenter;
}

// 根据圆中心点，半径，以及turf工具切出圆的一部分点
export function tangentialCircle(position, radius) {
  let center = position;
  let radiusNum = radius / 1000;
  let options = {steps: 1000, units: 'kilometers'}; // 定义切1000份点，如要圆更规则可改变大小，切出的点越多圆越规则
  let circle = turf.circle(center, radiusNum, options);
  let ar = [];
  let points = circle.geometry.coordinates[0];
  let linestring = turf.lineString(points);
  let positions = linestring.geometry.coordinates;
  for (let i = 0; i < positions.length; i++) {
    ar.push(positions[i][0]);
    ar.push(positions[i][1]);
  }
  return Cesium.Cartesian3.fromDegreesArray(ar);
}


