import Point from "../../base/Point";
import route_home from "@/assets/img/map/route_home.png"

export default class RouteNodePoint extends Point{
    static R = 10
    constructor(options){
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 24
        canvas.height = 24
        let image
        let pixelOffset = Cesium.Cartesian2.ZERO
        if (options.index == '1') {
            image = route_home;
            pixelOffset = new Cesium.Cartesian2(0, -12)
        } else {
            ctx.beginPath();//按照下边参数开始绘制新路径
            ctx.arc(12, 12, RouteNodePoint.R, 0, Math.PI*2, true);//（圆心X坐标，圆心Y坐标，半径，开始角度（弧度），结束角度弧度，是否按照顺时针画）
            ctx.closePath();//关闭路径
            ctx.fillStyle = '#EC3FF7';//设置填充颜色
            ctx.fill();//开始填充
            ctx.font = '12px lion';
            ctx.textAlign = 'center';
            ctx.fillStyle = '#FFFFFF';
            ctx.fillText(`${options.index}`, 12, 16);
            image = new Image();
            image.src = canvas.toDataURL("image/png")
        }
        options.style = {
            billboard: {
                image: image,
                pixelOffset: pixelOffset,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
            }
        }
        super(options)
        this._index = options.index
    }

    get index () {
        return this._index
    }

    toHeighLight() {
        // const canvas = document.createElement('canvas');
        // const ctx = canvas.getContext('2d');
        // canvas.width = 24
        // canvas.height = 24
        // ctx.beginPath();
        // ctx.arc(12, 12, RouteNodePoint.R, 0, Math.PI*2, true);
        // ctx.closePath();
        // ctx.fillStyle = 'white';
        // ctx.fill();
        // ctx.font = '16px lion';
        // ctx.textAlign = 'center';
        // ctx.fillStyle = '#FF8900';
        // // ctx.fillText(`${this.index}`, 12, 18);
        // let image = new Image();
        // image.src = canvas.toDataURL("image/png")
        // this._entity.billboard = new Cesium.BillboardGraphics ({
        //     image: image,
        //     disableDepthTestDistance: Number.POSITIVE_INFINITY,
        //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        // })
    }

    cancelHeighLight() {
        // const canvas = document.createElement('canvas');
        // const ctx = canvas.getContext('2d');
        // canvas.width = 24
        // canvas.height = 24
        // ctx.beginPath();
        // ctx.arc(12, 12, RouteNodePoint.R, 0, Math.PI*2, true);
        // ctx.closePath();
        // ctx.fillStyle = 'white';
        // ctx.fill();
        // ctx.font = '16px lion';
        // ctx.textAlign = 'center';
        // ctx.fillStyle = '#333333';
        // // ctx.fillText(`${this.index}`, 12, 18);
        // let image = new Image();
        // image.src = canvas.toDataURL("image/png")
        // this._entity.billboard = new Cesium.BillboardGraphics ({
        //     image: image,
        //     disableDepthTestDistance: Number.POSITIVE_INFINITY,
        //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        // })
    }
}

