import Point from "../../base/Point";
import NodeMap from "../../index";
import planeIcon from "@/assets/img/map/XC_64x64_.png";

export default class Uav extends Point {
  static DRAWTYPE = "Uav";
  constructor(options) {
    let defaultProps = Object.assign({
      style: {
        position: options.position,
        billboard:{
          image: options.planeIcon? options.planeIcon: planeIcon,
          width: 56,
          height: 56,
          rotation: -Cesium.Math.toRadians(Number(options.uavYaw? options.uavYaw: 0)),
        }
      },
      category: NodeMap.Node.UAV,
    }, options)
    super(defaultProps);
    this._drawType = Uav.DRAWTYPE
    this._canEdit = false
    this.initUav(defaultProps);
  }

  get createBy() {
    return this._createBy;
  }

  get canEdit() {
    return this._canEdit
  }

  set createBy(val) {
    this._createBy = val;
  }

  get drawType(){
    return this._drawType
  }

  initUav() {
    this.entity.drawType = Uav.DRAWTYPE;
  }

  onSelect(){}
  unSelect(){}
  hover() {}
  leave() {}
  toHeighLight() {}
  cancelHeighLight() {}
}
