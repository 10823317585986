import Polygon from "../base/Polygon";
import MarkInflectionPoint from "./MarkInflectionPoint";
import NodeMap from "../index"
import { getTemporaryMarklinetStyle } from "../../style/index";
export default class TemporaryMarkPolygon extends Polygon {
  static DRAWTYPE = "TemporaryMarkPolygon";
  static INFLECRTION_R = 10
  static INFLECRTION_NAME = '-Inflection-Point-TemporaryMarkPolygon'
  static COLOR = "#1164FF"
  static ALPHA = "33"
  constructor(options) {
    options = Object.assign({
      color: TemporaryMarkPolygon.COLOR
    }, options)
    let defaultProps = Object.assign({
      style: getTemporaryMarklinetStyle(options),
      category: NodeMap.Node.MARK,
    }, options)
    super(defaultProps);
    this._color = defaultProps.color
    this._nodes = []
    this._drawType = TemporaryMarkPolygon.DRAWTYPE
    this._createBy = defaultProps.createBy ? defaultProps.createBy : "";
    this._nodes = [];
    this._canEdit = false
    this.initTemporaryMarkPolygon(defaultProps);
  }

  get positions() {
    return this._positions;
  }

  set positions(positions) {
    try {
      for (let i = 0; i < positions.length; i++) {
        const element = positions[i];
        if (!element instanceof Cesium.Cartesian3) {
          throw "positions的元素为Cesium.Cartesian3类型";
        }
      }
      let points = this.getInflectionPoint();
      this._entity.position = positions[0];

      // 更新节点位置
      for (let index = 0; index < positions.length; index++) {
        const position = positions[index];
        points[index].position = position;
      }
      this._positions = positions;
    } catch (error) {
      console.error(error);
    }
  }

  get createBy() {
    return this._createBy;
  }

  get nodes() {
    return this._nodes;
  }

  get drawType() {
    return this._drawType
  }

  get canEdit () {
    return this._canEdit
  }

  get color() {
    return this._color
  }

  initTemporaryMarkPolygon(defaultProps) {
    // 初始化折线节点
    this.initPoints(defaultProps);
    this.entity.polyline =new Cesium.PolylineGraphics({
      width: 5,
      material: Cesium.Color.fromCssColorString(TemporaryMarkPolygon.COLOR),
      positions: new Cesium.CallbackProperty(()=>{
        return this.positions.concat(this.positions[0])
      }, false),
      clampToGround: true,
    })
    this.entity.polygon = new Cesium.PolygonGraphics({
      show: defaultProps.show,
      hierarchy: new Cesium.CallbackProperty(()=>{
        return new Cesium.PolygonHierarchy(this.positions)
      }, false),
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      material: Cesium.Color.fromCssColorString(this.color + TemporaryMarkPolygon.ALPHA)
    })
    this._entity.canEdit = false;
    this._entity.drawType = TemporaryMarkPolygon.DRAWTYPE;
  }

  draw(positions){
    this.positions = positions
  }

  initPoints(defaultProps) {
    for (var i = 0; i < this.positions.length; i++) {
      // 增加折线节点
      const position = this.positions[i];
      let show = i === this.positions.length - 1? false: true
      var point = new MarkInflectionPoint({
        id: Cesium.createGuid(),
        position: position,
        name: this.name + TemporaryMarkPolygon.INFLECRTION_NAME,
        index: `${i + 1}`,
        show,
        parent: this,
        color: defaultProps.color,
        R: TemporaryMarkPolygon.INFLECRTION_R
      });
      point.entity.canEdit = false;
      point.entity.drawType = TemporaryMarkPolygon.DRAWTYPE;
      this.addNode(point);
    }
  }

  addInflectionPoint(position) {
    var point = new MarkInflectionPoint({
      id: Cesium.createGuid(),
      position: position,
      name: this.name + TemporaryMarkPolygon.INFLECRTION_NAME,
      index: `${this.positions.length }`,
      show: false,
      parent: this,
      color: this.color,
      R: TemporaryMarkPolygon.INFLECRTION_R   
    });
    const points = this.getInflectionPoint()
    points[points.length -1].show = true
    point.entity.canEdit = false;
    point.entity.drawType = TemporaryMarkPolygon.DRAWTYPE;
    this._entity.editLayer.add(point.entity);
    this.addNode(point);
  }

  getInflectionPoint() {
    let nodes = [];
    for (let i = 0; i < this.nodes.length; i++) {
      const node = this.nodes[i];
      if (node instanceof MarkInflectionPoint) {
        nodes.push(node);
      }
    }
    return nodes;
  }

  isOwerInflectionPoint(node) {
    return this.nodes.findIndex(item=>{
      return item.id === node.id
    }) > -1
  }

  addNode(node){
    this._nodes.push(node);
  }
}
